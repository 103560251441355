// Store timeout IDs, use in : clearAllTimeouts, addTimeout
// use to have a debounce effect on hover events in menu
let timeouts = {
    general: [],
};

$(function () {
    // PROMO BAR
    if ($(".promo_swiper .swiper-slide").length > 1) {
        promoSwiper = new Swiper(".promo_swiper", {
            centeredSlides: true,
            loop: true,
            slidesPerView: 1,
            speed: 1500,
            autoplay: {
                delay: 4500,
                disableOnInteraction: false,
            },
            autoplayDisableOnInteraction: false
        });
    }
    
    $("#trigger_recherche").on("click touch", function () {
        if ($('.wrap_search_field').hasClass('actif')) {
            $('.wrap_search_field').removeClass("actif");
            $('#shad').removeClass("actif for_search");
        } else {
            $('.wrap_search_field').addClass("actif");
            $('#shad').addClass("actif for_search");
        }

        if ($('#wrapper_top_menu').hasClass('actif')) {
            $('#wrapper_top_menu').removeClass("actif");
            $('#shad').removeClass("for_menu");
            $('.menu_tablet').removeClass('open');
        }

        if ($('#filter_sticky').hasClass('actif')) {
            closeFiltre();
            $('#shad').addClass("actif header_visible");
        }
    });

    $('#abox .close_pop').on("click touch", function () {
        $('#abox, #shad_abox').removeClass('actif');
    });

    $(".closeBtn, #shad").on("click", function () {
        close_search_form();
        $('#wrapper_top_menu').removeClass('actif');
        $('#filter_sticky, #product_price_order').removeClass('actif');
    });

    // Lien reassurance FAQ dans le footer
    if (~window.location.href.indexOf("#paiement")) {
        window.setTimeout(function() {
            $('.faq_theme_3 .faq_title').click();
        }, 200);
    }

    if (~window.location.href.indexOf("#payment")) {
        window.setTimeout(function() {
            $('.faq_theme_3  .faq_title').click();
        }, 200);
    }

    if (~window.location.href.indexOf("#retours_possibles")) {
        window.setTimeout(function() {
            $('.faq_theme_5 .faq_title').click();
        }, 200);
    }

    if (~window.location.href.indexOf("#possible_returns")) {
        window.setTimeout(function() {
            $('.faq_theme_5 .faq_title').click();
        }, 200);
    }

    $("#search_home").on("keypress", function (event) {
        if (event.which === 13) {
            close_search_form();
        }
    });

    $("#search_home").on('input', function() {
        var value = $("#search_home").val();

        if(value.length >= 3){
            $('body').addClass('search_in');
        } else {
            $('body').removeClass('search_in');
        }
    });

    adjustHeader();
    adjustMenu();

    $(window).resize(function() {
        adjustMenu();
    });

    $(window).scroll(function() {
        adjustHeader();
    });

    // Formatting into groups of 2 digits separated by a space
    $('#telephone').on('input', function() {
        let value = $(this).val().replace(/\D/g, '');
        let formattedValue = '';

        for (let i = 0; i < value.length; i += 2) {
            if (i > 0) {
                formattedValue += ' ';
            }
            formattedValue += value.substring(i, i + 2);
        }

        $(this).val(formattedValue.trim());
    });

    if ($('#cart_list .product-link').length) {
        $('#cart_list').overlayScrollbars({
            overflowBehavior: {
                x: 'hidden',
            },
        });
    }

    if ($('#cart_list_wish .product-link').length) {
        $('#cart_list_wish').overlayScrollbars({
            overflowBehavior: {
                x: 'hidden',
            },
        });
    }
    
    // PLAN DU SITE DISPLAY COLUMN
    if ($('body.footer_sitemap').length) {
        $('.footer_sitemap .sitemap_section').each(function() {
            if ($(this).find(".article").length > 8) {
                $( this ).addClass( "column" )           
            }
        });

        $( '.sitemap_section.column' ).each( function () {
            $(this).find('.article').wrapAll( '<div class="two_columns" />' );
        });
    }

    // COVER MODULE SWIPER
    if ($('#cover_module_swiper .swiper-wrapper').length) {
        var coverSwiper = new Swiper('#cover_module_swiper', {
            slidesPerView: 1,
            loop: true,
            speed: 2000,
            autoplay: {
                delay: 5000,
            },
            pagination: {
                el: "#cover_module_swiper .swiper-pagination",
                type: "bullets",
                clickable: true,
            }
        });
    }

    // TG MODULE SWIPER
    $('body').on('mouseenter touchstart', '.push-product-module', function() {
        var $this = $(this);
        
        if ($this.find('.pushProductHome.swiper-container').length && !$this.data('swiper-initialized')) {
            var tgSwiper = new Swiper($this.find('.pushProductHome.swiper-container')[0], {
                slidesPerView: 3,
                spaceBetween: 19,
                pagination: {
                    el: $this.find('.tg-module.swiper-pagination')[0],
                    type: "bullets",
                    clickable: true,
                },
                breakpoints: {
                    1441: {
                        slidesPerView: 4,
                    },
                }
            });
            $this.data('swiper-initialized', true);
        }
    });


    // SWIPER TG PRODUITS
    $('body').on('mouseenter touchstart', '.tg-module', function() {
        if ($('.tg-module .block_lnk.swiper-container').length) {
            var tg_module = new Swiper('.tg-module .block_lnk.swiper-container', {
                slidesPerView: 1,
                centeredSlides: true,
                allowTouchMove: true,
                spaceBetween: 0,
                lazy: {
                  loadPrevNext: true,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            });
        }
    });

    // FP
    if ($('#assoc_product_slider .swiper-slide').length > 2) {
        var productVisitedSwiper = new Swiper('#assoc_product_slider', {
            slidesPerView: 3,
            spaceBetween: 19,
            roundLengths: true,
            pagination: {
                el: "#assoc_product_slider .swiper-pagination",
                type: "bullets",
                clickable: true,
            },
            breakpoints: {
                1440: {
                    slidesPerView: 4,
                },
            }
        });
    }

    // Rayon
    if ($('.slideCateg .swiper-slide').length > 5) {
        var slideCateg = new Swiper('.slideCateg', {
            slidesPerView: 'auto',
            spaceBetween: 0,
            navigation: {
                nextEl: ".bloc_sub_categ .swiper-button-next",
                prevEl: ".bloc_sub_categ .swiper-button-prev",
            },
        });
    }

    $('body').on('mouseenter', '.dynasearchwrapper', function() {
        if ($('.search_sub_categ').length) {
            var search_sub_categ = new Swiper('.search_sub_categ', {
                slidesPerView: 'auto',
                navigation: {
                    nextEl: ".button-sub-categ-next",
                    prevEl: ".button-sub-categ-prev",
                },
            });
        }
    });

    $('body').on('mouseenter touchstart', '#list_item > .item', function() {
        var $item = $(this);
        var this_swiper_slide = $item.find(".block_lnk .swiper-slide").length;
        var this_swiper_item = $item.attr("id"); 
        var this_swiper_item_container = "#" + this_swiper_item + " .productVisualMulti > .swiper-container, #" + this_swiper_item + " .productVisualMulti_home > .swiper-container"; 

        if (this_swiper_slide > 1 && !$(this_swiper_item_container).hasClass("swiper-initialized")) {
            var this_swiper_button_next = $item.find('.productVisualMulti .swiper-button-next, .productVisualMulti_home .swiper-button-next').attr('class'); // Get the next button class
            var this_swiper_button_prev = $item.find('.productVisualMulti .swiper-button-prev, .productVisualMulti_home .swiper-button-prev').attr('class'); // Get the previous button class

            if (this_swiper_button_next && this_swiper_button_prev) {
                new Swiper(this_swiper_item_container, {
                    slidesPerView: 1,
                    navigation: {
                        nextEl: "." + this_swiper_button_next,
                        prevEl: "." + this_swiper_button_prev,
                    },
                });
                $(this_swiper_item_container).addClass("swiper-initialized");
            }
        }
    });

    $(document).on("click touch", ".remove_top_wishlist", function (ev) {
        var id = $(this).attr("data-id");
        var current_elem = $(this);

        $.post(path_relative_root + create_link("ajax_remove_from_wishlist.php"), { id: id }, function (data) {
            var nr;

            if (data.result == undefined) {
                data.result = $('.wish_liste_product .product_ctn').length;

            } else {
                nr = data.result.wishlistLength;
            }

            if (nr == 0) {
                $("#wishlist_top .nbProd").html('');
                $("#show_top_cart").addClass('hide');
                $(".item.wishlist").hide();
                topCartDesappear();
            } else {
                $("#wishlist_top .nbProd").html(nr);
            }

            var response_html = ajax_file(path_relative_root + 'ajax_show_wishlist.php');

            $("#wishlist_top").html(response_html);

            $(current_elem.parents('.product-link')).fadeOut(100, function () {
                current_elem.parents('.product-link').remove();
            });
        });
    });

    $(document).on("click","#btn_top_add_wishlist", function (e) {
        e.preventDefault();
        selectedProducts = [];
        $(".remove_top_wishlist").each(function( index ){
            var id = $(this).attr("data-id");
            selectedProducts.push(id);

        });
        moveProductFromWishlistToCart(selectedProducts);
    });

    function moveProductFromWishlistToCart(array_wishlist_product_ids) {
        $.post(path_relative_root + create_link('ajax_add_panier_wishlist'), {data: array_wishlist_product_ids.join(",")}, function(resp) {
            if (resp == "ok") {
                document.location.href = path_relative_root + create_link('order_basket');
            }
        });
    }

    // Fiche produit desciption
    $(document).on("click", ".toggleCarac", function() {
        var nextContent = $(this).next('.content');

        $('.toggleCarac').not(this).removeClass('actif');
        $('.toggleCarac').not(this).next('.content').removeClass('actif').slideUp();
        
        if ($(this).hasClass('actif')) {
            $(this).removeClass('actif');
            nextContent.removeClass('actif').slideUp();
        } else {
            $(this).addClass('actif');
            nextContent.addClass('actif').slideDown();
        }
    });

    // Guides des tailles
    $(document).on("click", ".guide_feats_wrapper .thumblnk", function() {
        var nextContent_gdt = $(this).next('.thumbslide');

        $('.guide_feats_wrapper .thumblnk').not(this).removeClass('actif');
        $('.guide_feats_wrapper .thumblnk').not(this).next('.thumbslide').removeClass('actif').slideUp();
        
        if ($(this).hasClass('actif')) {
            $(this).removeClass('actif');
            nextContent_gdt.removeClass('actif').slideUp();
        } else {
            $(this).addClass('actif');
            nextContent_gdt.addClass('actif').slideDown();
        }

        $('#sizeguideBox .rollover_gdt_content').overlayScrollbars({
            overflowBehavior: {
                x: 'hidden',
            },
        });
    });

    if ($('.cart .basket_gondole').length) {
        $('.basket_gondole .swiper-slide.gondole').wrapAll('<div class="swiper swiper-container basket_gondole_swiper"><div class="swiper-wrapper"></div></div>');

        // TG MODULE SWIPER
        if ($( '.basket_gondole .swiper.swiper-container').length ) {
            var panierSwiper = new Swiper('.basket_gondole .swiper.swiper-container', {
                slidesPerView: 3,
                spaceBetween: 19,
                pagination: {
                    el: ".tg-module.swiper-pagination",
                    type: "bullets",
                    clickable: true,
                },
                breakpoints: {
                    // when window width is <= 1440px
                    1440: {
                        slidesPerView: 4,
                    },
                }
            });
        }
    }

    if ($('.cart .basket_gondole').length) {
        $('.cart .basket_gondole').appendTo('#site_global_wrap');
    }

    if ($('.wrapper_basket_content .bank_left_side .wrapper_payment_mode .selectPaymentModeTitle').length) {
        $('.wrapper_basket_content .bank_left_side .wrapper_payment_mode .selectPaymentModeTitle').prependTo('.bank_left_side');
    }

    if ($('.body_login.in_tunnel .wrapper_tunnel').length) {
        $('.body_login.in_tunnel .tunnel_track_pusher').appendTo('.newlogin_page .login_wrapper');
        $('.tunnel_track_pusher').prependTo('.login_wrapper');
    }

    if ($('.thanksLeftWrapper .createPwdTxt').length) {
        $('.thanksLeftWrapper .createPwdTxt').appendTo('.thanksRight');
    }

    if ($('.thanksLeftWrapper .w-form').length) {
        $('.thanksLeftWrapper .w-form').appendTo('.thanksRight');
    }

    if ($('.thanksLeftWrapper .bankThanksBtn').length) {
        $('.thanksLeftWrapper .bankThanksBtn').appendTo('.thanksRight');
    }

    if ($('#lightbox_preco .precoWarning').length) {
        $('#lightbox_preco .precoWarning').appendTo('.blocPrecoWrapper');
    }

    if ($('.cart .main_wrapper #bandeau_basket_premium').length) {
        $('.cart .main_wrapper #bandeau_basket_premium').prependTo('#tunnel_right_col');
    }

    $('body').on('click touchstart', '.close_promo', function () {
        $('body').removeClass('hasBando pheader');
        $('.promo_bar').remove();
    });

    if ($('.wrapper_panier .product_sfs_warning_txt').length) {
        $('.wrapper_panier .product_sfs_warning_txt').prependTo('.left_side');
    }

    $(window).scroll(function(){
        var scrollTop = $(window).scrollTop();
        var $productCmsBloc = $('.product_cms_bloc');
        var priceText = $('.price_and_alma_wrapper .price_container .price:nth-child(1)').clone().find('.no_cents').remove().end().text();

        if ($productCmsBloc.length > 0) {
            var elementOffset = $productCmsBloc.offset().top;
            
            if (scrollTop > elementOffset) {
                $('.product_info').addClass('sticky_info');
                $('#btn_add_cart').val((Translator.translate('add_basket')) + ' | ' +  priceText);
            } else {
                $('.product_info').removeClass('sticky_info');
                $('#btn_add_cart').val((Translator.translate('add_basket')));
            }
        }
    });

    if ($('.wrapper_tunnel .newlogin_page:not(.vp_connexion) .acc_login_title').length) {
        var my_account_log = '<span>' + Translator.translate('my_account_log') + '</span>';
        
        $(".wrapper_tunnel .newlogin_page:not(.vp_connexion) .acc_login_title").html(my_account_log);
        $(".wrapper_tunnel .newlogin_page:not(.vp_connexion) .acc_login_title").show();
    }

    if ($('.cart .wrapper_tunnel .newlogin_page .login_form_wrapper .title #subtitleLogin').length) {
        var newlogin2_txt_2 = Translator.translate('newlogin2_txt_2');
        
        $(".cart .wrapper_tunnel .newlogin_page .login_form_wrapper .title #subtitleLogin").html(newlogin2_txt_2);
        $(".cart .wrapper_tunnel .newlogin_page .login_form_wrapper .title #subtitleLogin").show();
    }

    if ($('.cart .wrapper_tunnel .newlogin_page .login_bloc+.login_bloc .subtitle').length) {
        var account_quick_access_tunnel_txt_02 = Translator.translate('account_quick_access_tunnel_txt_02');
        
        $('.cart .wrapper_tunnel .newlogin_page .login_bloc+.login_bloc .subtitle').html(account_quick_access_tunnel_txt_02);
        $('.cart .wrapper_tunnel .newlogin_page .login_bloc+.login_bloc .subtitle').show();
    }

    if ($('.total_produit.toggle').length && $('.total_produit_dropdown').length) {
        var toggler = $('.total_produit.toggle');
        var my_total_product_02 = Translator.translate('total_product_02');

        // Saving product count
        var nbProd = $('.under_dropdown').find('.nb_products');

        // Changing title of toggler
        toggler.find('label').text(my_total_product_02).append(nbProd);
        
        toggler.css('display', 'flex');
    }

    if ($('.wrapper_panier .wrapper_form_adresse.tunnel_form .delivery_section_title').length) {
        var complete_delivery_information2 = Translator.translate('complete_delivery_information2');
        
        $(".wrapper_panier .wrapper_form_adresse.tunnel_form .delivery_section_title").html(complete_delivery_information2);
        $(".wrapper_panier .wrapper_form_adresse.tunnel_form .delivery_section_title").show();
    }

    // We add the "w-nonempty" class in the password field if it is not present when loading and the field is not empty
    if ($('.w-form').length) {
        $('.w-form').each( function () {
            var inputParent = $(this).find('.w-password-input');
            var input = inputParent.find('input.w-input-element');

            if (input.val() !== '' && input.val() !== undefined && !inputParent.hasClass('w-nonempty')) {
                inputParent.addClass('w-nonempty');
            }
        });
    }

    if ($('.holder_wrapper').length) {
        $('.holder_wrapper').overlayScrollbars({
            overflowBehavior: {
                x: 'hidden',
            },
        });
    }

    if ($('#wrapper_form_adresse').length) {
        $('#wrapper_form_adresse').overlayScrollbars({
            overflowBehavior: {
                x: 'hidden',
            },
        });
    }

    if ($('.js-form-selection-offer').length) {
        $('.js-form-selection-offer').each( function () {
            $(this).find('.color_list').removeClass('swiper-container');
            $(this).find('.size_list').removeClass('swiper-container');
        });
    }

    $(document).ready(function() {
        window.setTimeout(function() {
            if ($('.payez_en_3_fois_sans_frais').length) {
                $('.payez_en_3_fois_sans_frais').slideDown(500);  
            }
        }, 2000);
    });

    if ($('#trigger_filtre').length) {
        updateCheckedCount();
    }

    if ($('#list_item').length) {
        if (readCookie("nb_produits") == 6) {
            viewBy('bySix', 'bydefault');
        } else {
            viewBy('bydefault', 'bySix');
        }
    }

    if ($('.product_page #btn_add_alert').length) {
        window.setTimeout(function() {
            $('#btn_add_alert').removeClass('cache');
        }, 500);
    }

    $('#btn_cmd_valid span').click(function () {
        $(this).parent().addClass('loading');

        setTimeout(function () {
            $('#btn_cmd_valid').removeClass('loading');
        }, 2000);
    });

    updateCartUI();

    const observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            updateCartUI();
        });
    });

    observer.observe(document.body, { childList: true, subtree: true });
});
// Add product to basket from wishlist (connected)
function moveProductFromWishlistToCart(elemBtn = false, selectedProducts = {}) {
    let datas = {};

    if (elemBtn) {
        var prodId = $(elemBtn).attr("data-produit-id");
        var colorId = $(elemBtn).attr("data-color-id");
        var sizeId = $(elemBtn).attr("data-size-id");

        datas = {
            data: $(elemBtn).attr("data-id"),
        };

        if (prodId !== undefined) {
            datas.productId = prodId;
        }

        if (colorId !== undefined) {
            datas.colorId = colorId;
        }

        if (sizeId !== undefined) {
            datas.sizeId = sizeId;
        }
    } else {
        datas = selectedProducts;
    }

    $.post(path_relative_root + create_link("ajax_add_panier_wishlist"), datas, function(resp) {
        var data_layer_products = window.data_layer_products;
        var dtl_products = [];
        if (resp == "ok") {
            if (window.dataLayer && data_layer_products) {
                if (Object.keys(data_layer_products).length > 0) {
                    for (const property in data_layer_products) {
                        if (array_wishlist_product_ids.includes(parseInt(property))) {
                            dtl_products.push(data_layer_products[property])
                        }
                    }
                }

                if (dtl_products.length > 0) {
                    window.dataLayer.push({
                        "event": "addToCart",
                        "ecommerce": {
                            'currencyCode' : siteCurrency.code,
                            "add": {
                                "products": dtl_products
                            }
                        }
                    })
                }
            }
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}
$(document).on("click","#btn_top_add_wishlist", function (e) {
    e.preventDefault();

    let datas = [];
    let selectedTopProducts = {};

    $(".remove_prod.wishlist_remove").each(function() {

        datas.push({
            productId: $(this).attr("data-produit-id"),
            colorId: $(this).attr("data-color-id"),
            sizeId: $(this).attr("data-size-id"),
        });
    });

    selectedTopProducts.selectedProducts = JSON.stringify(datas);
    moveProductFromWishlistToCart(false, selectedTopProducts);
});
/**
 * Display lightbox contact store
 */
function showLightboxContactStore() {

    var shad = document.getElementById('shad');
    var modbox = document.getElementById('pdvBox');

    $('html, body, .os-viewport').animate({
        scrollTop: 0
    }, 500);

    $( modbox ).addClass( 'actif' );
    $('#ligthbox_send_store_information').addClass('actif');
    $(shad).addClass('actif');
    $(shad).on('click', closeLightboxContactStore);
    $('#content_pdvBox').show();

    setTimeout(function () {

        $('#numTelephone').intlTelInput();
    }, 400);
}

/**
 * Close lightbox contact store
 */
function closeLightboxContactStore() {
    var shad = document.getElementById('shad');
    var modbox = document.getElementById('pdvBox');

    $(modbox).removeClass('actif');
    $( shad ).removeClass( 'actif' );
    $('#ligthbox_send_store_information').removeClass('actif');
}

function hide_lightbox_send_info() {
    closeMultiShad('ligthbox_send_store_information');
}

function close_search_form() {
    $('.wrap_search_field').removeClass('actif');
    $('#shad').removeClass('actif for_search');
}

function adjustHeader() {
    if ($(window).scrollTop() > 80) {
        $('#site_head_wrap').addClass('sticky');
        if ($('.promo_bar').length) {
            $('.promo_bar').slideUp(500);
        }
    } else {
        $('#site_head_wrap').removeClass('sticky');
        if ($('.promo_bar').length) {
            $('.promo_bar').slideDown();
        }
    }
}

var overlayScrollbarsInstance;

function adjustMenu() {
    var windowWidth = window.innerWidth;

    $('.menu_itm_hover > a').off('click');
    $('.menu_itm_hover').off('mouseenter mouseleave');
    $('#site_head_wrap').off('click', '.menu_tablet');
    $('#cart_top a.basket_head, #wishlist_top a.wish_head').off('click touchend');
    $('.close_rollover').off('click');
    $('#ctaAvailabilityInStoreTitle').off('mouseenter click mouseleave');
    $(".mainSlideFp .swiper-slide").off("mousemove mouseenter mouseleave");

    if (windowWidth <= 1270) {
        $('body').addClass('tablet_mode').removeClass('desktop_mode');

        $(".tablet_mode #wrapper_top_menu .menu_itm_hover > a").each(function () {
            $(this).on('click', function(event) {
                event.preventDefault();

                var isActive = $(this).parent('.menu_itm_hover').hasClass('actif');
                
                // If element is not active
                if (!isActive) {
                    $(this).parent('.menu_itm_hover').addClass('actif').find('.menu_categ_wrapper').slideDown();
                    $('.tablet_mode #wrapper_top_menu .menu_itm_hover > a').off('click');
                    
                    setTimeout(function() {
                        $(".tablet_mode #wrapper_top_menu .menu_itm_hover > a").each(function () {
                            $(this).on('click', function(event) {
                                event.preventDefault();
                                var isActive = $(this).parent('.menu_itm_hover').hasClass('actif');
                                $('.tablet_mode .menu_itm_hover').removeClass('actif');
                                $('.tablet_mode .menu_categ_wrapper').slideUp();
                                
                                if (!isActive) {
                                    $(this).parent('.menu_itm_hover').addClass('actif');
                                    $(this).parent('.menu_itm_hover').find('.menu_categ_wrapper').slideDown();
                                }
                            });
                        });
                    }, 500);
                }
            });
        });

        $('.tablet_mode #site_head_wrap').on('click', '.menu_tablet', function () {
            if ($('.tablet_mode #wrapper_top_menu').hasClass('actif')) {
                $('.tablet_mode #wrapper_top_menu').removeClass("actif");
                $('#shad').removeClass("actif for_menu");
                $(this).removeClass('open');
            } else {
                $('.tablet_mode #wrapper_top_menu').addClass("actif");
                $('#shad').addClass("actif for_menu");
                $(this).addClass('open');
            }

            // Initialize OverlayScrollbars instance if it doesn't exist or update it
            if (!overlayScrollbarsInstance) {
                overlayScrollbarsInstance = OverlayScrollbars($('#wrapper_top_menu .main_menu.main_wrapper')[0], {
                    overflowBehavior: {
                        x: 'hidden',
                    },
                });
            } else {
                overlayScrollbarsInstance.options({
                    overflowBehavior: {
                        x: 'hidden',
                    },
                });
            }
        });

        var touchDevice = 'ontouchstart' in window || navigator.maxTouchPoints;

        if (touchDevice) {
            var touchClicks = 0;

            $('#cart_top a.basket_head, #wishlist_top a.wish_head').on('click touchend', function(e) {
                e.preventDefault();
                touchClicks++;

                if (touchClicks === 1) {
                    // First click
                    $(this).off('click');
                } else {
                    // 2nd click
                    $(this).on('click', function(e) {
                        window.location.href = $(this).attr('href');
                    });
                }
            });
        }

        $('.tablet_mode #site_head_wrap').on('click', '.close_rollover', function () {
            $(".headerRollover:not(#distrib_top)").removeClass('hover');
        });

    } else {
        $('body').addClass('desktop_mode').removeClass('tablet_mode');

        // Destroy OverlayScrollbars instance if it exists
        if (overlayScrollbarsInstance) {
            overlayScrollbarsInstance.destroy();
            overlayScrollbarsInstance = null;
        }

        // Hide all menu_categ_wrapper
        $('.menu_categ_wrapper').hide();
        $('.menu_itm_hover').removeClass('actif');

        $('.desktop_mode #wrapper_top_menu .menu_itm_hover')
            .on('mouseenter', function () {
                clearAllTimeouts('general');
                $('#shad_menu').addClass("actif");
                $(this).find('.menu_categ_wrapper').stop().fadeIn().css('display', 'flex');
                $(this).addClass('actif');
            })
            .on('mouseleave', function () {
                $(this).removeClass('actif');
                $(this).find('.menu_categ_wrapper').stop().fadeOut('fast');

                addTimeout('general', () => {
                    if (!$(".desktop_mode #wrapper_top_menu .menu_itm_hover:hover").length) {
                        $('#shad_menu').removeClass("actif");
                    }
                }, 100);
            });        
    }

    $(".headerRollover:not(#distrib_top)").each(function () {
        $(this).on('mouseenter', function() {
            closeFiltre();
            $('#shad').addClass("actif header_visible");
            
        }).on('mouseleave', function() {
            $('#shad').removeClass("actif header_visible");
            close_search_form();
        });
    });

    $(".mainSlideFp .swiper-slide").on("mousemove", function(e) {
        var $container=$(this);
        var $hoverImage=$container.find('.img_zoom');
        var containerOffset=$container.offset();
        var mouseX=e.pageX-containerOffset.left;
        var mouseY=e.pageY-containerOffset.top;
        var imageWidth=$container.width();
        var imageHeight=$container.height();
        var scaleX=mouseX/imageWidth;
        var scaleY=mouseY/imageHeight;

        // Calculation of the position of the large image
        var hoverImageWidth=$hoverImage.width();
        var hoverImageHeight=$hoverImage.height();
        var hoverImageX=mouseX-(hoverImageWidth*scaleX);
        var hoverImageY=mouseY-(hoverImageHeight*scaleY);

        $hoverImage.css({
            transformOrigin:scaleX*100+"% "+scaleY*100+"%",
            left:hoverImageX+"px",
            top:hoverImageY+"px"
        });
    });

    if ($(".mainSlideFp .swiper-slide").length) {
        $(".mainSlideFp .swiper-slide").on("mouseenter", function() {
            $(this).find('.img_zoom').addClass('active');
        });

        $(".mainSlideFp .swiper-slide").on("mouseleave", function() {
            $(this).find('.img_zoom').removeClass('active');
        });
    }

    if ($("#ctaAvailabilityInStoreTitle").length) {
        $("#ctaAvailabilityInStoreTitle").on({
            mouseenter: function(){
                $("#sizeerror").addClass("active");
            },
            click: function(){
                $("#sizeerror").addClass("active");
            },
            mouseleave: function(){
                $("#sizeerror").removeClass("active");
            }
        });
    }
}

// Function to clear all timeouts in a specific category
function clearAllTimeouts(category) {
    timeouts[category].forEach(clearTimeout);
    timeouts[category] = [];
}

// Function to set a timeout in a specific category and add to the list
function addTimeout(category, callback, delay) {
    const id = setTimeout(callback, delay);
    timeouts[category].push(id);
}

//Remove product from cart header rollover
function ajax_RemoveBasketProd(basket_id, prod_id, qte, type) {
    var isService = (type == 'service') ? true : false;
    $.ajax({
        type: 'post',
        data: {
            basket_id: basket_id,
            prod_id: prod_id,
            qte: qte,
            is_service : isService
        },
        url: path_relative_root + 'ajax_removeBasketProd.php',
        success: function (res) {

            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            $('#cart_top').html(res.datas.html);

            if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && prod_id !== null && prod_id !== undefined) {
                DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, prod_id, "remove_from_cart", qte);
            }
        }
    });
}

// Add to wishlist the selected product from header rollover basket
/**
 * @description Recovers stock sizes by color and their stock
 * 
 * @since 2022-11-04
 */
function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;
        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $('[name="itm_size"]').filter('[value="' + size_id + '"]');
                is_disabled = !sizes_tab[size_id];
                size_box.siblings();
                if (!is_disabled) {
                    size_box.removeClass('disabled');
                    $('#label_' + size_id).removeClass('disabled');
                } else {
                    size_box.addClass('disabled');
                    $('#label_' + size_id).addClass('disabled');
                }
            }
        }
    }
}

function topBasketToWish(product) {

    var productId = $(product).attr("data-productId");
    var sizeId = $(product).attr("data-sizeId");
    var colorId = $(product).attr("data-colorId");

    var data = {
        product_id: productId,
        size_id: sizeId,
        color_id: colorId,
    };

    if (!checkProductInWishlist(productId)) {

        $.post(path_relative_root + 'ajax_add_to_wishlist.php?time=' + Date.now(), data, function (resp) {

            if (resp.error) {
                if (resp.message == "login_required") {
                    var html =
                        '<div onclick="closeMultiShad(\'abox\')" class="closeBtn"></div><div class="box_wishlist">' +
                        resp.result.error_message +
                        '<div class="btn_container">' +
                        '<a class="button primary" href="#" onclick="wishlistRedirectLogin();">' +
                        resp.result.btn_connexion +
                        "</a>" +
                        "</div>" +
                        "</div>";

                    if ($('#abox').length) {
                        // If abox exists, injected newly created html inside
                        $('#abox').addClass('wishConnection actif');
                        $('#abox').html(html);
                    } else {
                        // If it does not exist, create it
                        var abox = '<div id="abox" class="lightbox wishConnection actif"></div>';
                        $('#appContainer').append(abox);
                        $(abox).html(html);
                    }

                    $('#shad_abox').addClass('actif');

                } else {
                    alert(Translator.translate(resp.result.error_message));
                    $('#shad_abox').addClass('actif');

                    return false;
                }
            } else {
                if (window.dataLayer !== undefined) {
                    window.dataLayer.push({
                        'event': 'addToWishlist',
                    });
                }
                var response_basket = ajax_file(path_relative_root + 'ajax_show_wishlist.php');
                var delete_rollover_panier = $(this).parents('.product-link');
                $(".block_top_links #wishlist_top").html(response_basket);
                $(".block_top_links #cart_top").removeClass('hover');
                $(".block_top_links #wishlist_top").addClass('hover');
                $(" #shad").addClass('header_visible');
                addToWishlistUpdateHeader(resp.result.wishlist_size);

                setTimeout(function () {
                   $(".block_top_links #wishlist_top").removeClass('hover');
                   $("#shad").removeClass('header_visible');
                }, 2000);

                if ($('#cart_list .product-link').length) {
                    $('#cart_list').overlayScrollbars({
                        overflowBehavior: {
                            x: 'hidden',
                        },
                    });
                }

                if ($('#cart_list_wish .product-link').length) {
                    $('#cart_list_wish').overlayScrollbars({
                        overflowBehavior: {
                            x: 'hidden',
                        },
                    });
                }
            }
        });

    } else {
        alert(Translator.translate('err_product_exist_in_wishlist'));

        $('#shad_abox').addClass('actif');
    }
}

function topWishToBasket(array_wishlist_product_ids) {
    var id = $(this).attr("data-id");
    var current_elem = $(this);

    $.post(path_relative_root + create_link('ajax_add_panier_wishlist'), { data: array_wishlist_product_ids.join(",") }, function (resp) {
        if (resp == "ok") {
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}

function cartShow() {
    $("#show_top_cart").addClass("open");
    $("#shad").addClass("actif header_visible");

    if ($('#cart_list .product-link').length) {
        $('#cart_list').overlayScrollbars({
            overflowBehavior: {
                x: 'hidden',
            },
        });
    }

    setTimeout(function () {
        $("#show_top_cart").removeClass("open");
        $("#shad").removeClass("actif header_visible");

        if ($('body').hasClass('cart')) {
            window.location.reload();
        }
    }, 3000);
}

alertV2 = (function () {

    "use strict";

    var shade, albox, alert_tmpl;
    var regex = new RegExp("\\s", "g");

    function init() {
        shade = document.getElementById("shad_abox");
        albox = document.getElementById("abox");
        alert_tmpl = albox.innerHTML.trim();

        window.removeEventListener("load", init);
    }

    function closeAlertBox() {
        var origin = $('.form_submit.loading');

        setTimeout(function() {
            $(albox).removeClass('alertV2');
        }, 400);
        
        $(albox).removeClass('actif');
        $(shade).removeClass('actif');
        $(shade).off("click", closeAlertBox);
        $('body').removeClass('alert_open');

        if(origin.length){
            $(origin).removeClass('loading');
        }
    }

    function alertV2(str) {
        $('body').addClass('alert_open');
        $('.txt_alert', albox).html(str);
        $('#abox .form_submit').css('display', 'block');
        $(".close", albox).on("click", closeAlertBox);
        $(shade).on("click", closeAlertBox);
        $(".close_pop").on("click", closeAlertBox);
        $(shade).addClass('actif');
        $(albox).fadeIn(400).addClass('alertV2 actif');
    }

    window.addEventListener("load", init);

    return alertV2;
}());

/* ------------------------------ STORE LOCATOR ------------------------------ */
// Creation d'un bloc correspondant a un marqueur sur la liste laterale
function creerLigneListe(
    cpt,
    id,
    nom,
    adresse,
    ville,
    cp,
    pays,
    currently_open,
    horaire_today,
    distance,
    marqueur,
    picture_filtre,
    magasinId,
    stock,
    stockStatus,
    stock_quantity,
    clientId,
    favorite,
    beContacted,
    flag_available,
    url,
    shop_filter,
    telephone,
    query,
    lat,
    lng
) {
    currently_open = parseFloat(currently_open);
    var li = document.createElement("li");
    li.id = id;
    li.dataset.lng = lng;
    li.dataset.lat = lat;

    li.className = "elem-list-store";

    if (lat != "" && lng != "" && !$("body.product_page").length) {
        li.setAttribute("onclick", "geolocGoto('" + lat + "', '" + lng + "')");
    }

    if (favorite == "1") {
        choose_fav = Translator.translate("actual_favorite_store");
    } else {
        choose_fav = Translator.translate("choose_as_favorite_store");
    }

    /***** Product stock infos *****/
    if (stock_quantity == "undefined" || stock_quantity == "") {
        stock_status_msg = "unknow_stock";
    } else {
        var in_stock = stock_quantity >= 6 && stock_quantity <= 9999;
        var limited_stock = stock_quantity >= 1 && stock_quantity <= 5;
        var not_in_stock = stock_quantity == 0;
        var to_be_confirmed_stock = stock_quantity == 10000;
        var can_be_ordered_stock = stock_quantity == 11000;

        var stock_status_msg = "";
        if (in_stock) {
            stock_status_msg = "in_stock";
        }
        if (limited_stock) {
            stock_status_msg = "limited_stock";
        }
        if (not_in_stock) {
            stock_status_msg = "not_in_stock";
        }
        if (to_be_confirmed_stock) {
            stock_status_msg = "to_be_confirmed_stock";
        }
        if (can_be_ordered_stock) {
            stock_status_msg = "can_be_ordered_stock";
        }
    }

    var info_horaire_today = !horaire_today
        ? Translator.translate("store_closed")
        : horaire_today;
    var class_horaire_today = currently_open ? "store_open" : "store_closed";
    var ligneListe =
        '<div class="elem_list_contents">' + '<div class="nom_store">';
    if (distance) {
        ligneListe +=
            '<h2 class="title">' +
            nom +
            '<p class="distance">' +
            "(" +
            distance +
            "km)" +
            "</p></h2>";
    } else {
        ligneListe += '<h2 class="title">' + nom + "</h2>";
    }
    ligneListe += "</div>" + '<div class="content_store">';

    if ($("body.product_page").length) {
        ligneListe += `<div class="detail_store" onclick="location.href = '${
            path_relative_root + url
        }'">`;
    } else {
        ligneListe += '<div class="detail_store">';
    }
    ligneListe +=
        '<div class="col-1">' +
        '<span class="rue_store">' +
        adresse +
        "</span>" + " - " + cp +
        " " +
        ville +
        '<span class="pays_store">' +  " - " +
        pays +
        "</span>" +
        "</span>" +
        '<span class="tel_store">' +
        telephone +
        "</span></div>";

    var is_page_product = $("body.product_page").length == 1;

    if (
        (info_horaire_today !== "" && currently_open) ||
        (!currently_open && horaire_today)
    ) {
        ligneListe +=
            '<div class="col-2"><p class="horaire_today puce_' +
            class_horaire_today +
            '">' +
            info_horaire_today +
            "</p></div>";
    }

    isFavorite = favorite ? "favorite_store_js" : "";
    ligneListe +=
        '<a href="javascript:;" class="addfavorite" id="store' +
        magasinId +
        '" onclick="addToMyFavorite(\'' +
        magasinId +
        "', '" +
        clientId +
        "', '" +
        favorite +
        "', '" +
        escape(info_horaire_today) +
        "','" +
        currently_open +
        "','" +
        escape(horaire_today) +
        "','" +
        class_horaire_today +
        "','" +
        stock +
        "','" +
        stockStatus +
        "'); return false;\">";
    ligneListe +=
        '<span class="choose_fav_label" id="favShop-' +
        magasinId +
        '"><span class="' +
        isFavorite +
        '">' +
        choose_fav +
        "</span></span></a>";

    ligneListe += "</div>";
    if (document.getElementById("produit_id")) {
        /***** Product stock infos msg *****/
        if (stock != "undefined") {
            ligneListe +=
                '<p class="stock_status ' +
                stock_status_msg +
                '">' +
                Translator.translate(stock_status_msg + "_msg") +
                "</p>";
        }
    }
    if (is_page_product) {
        if (beContacted == 1) {
            if ($("body.product_page.mobile").length) {
                ligneListe +=
                    '<div class="availableTxt store_more" onclick="handleChangeMobile(' +
                    id +
                    ')">' +
                    "<span>" +
                    txt_available_mag +
                    "</span>" +
                    "</div>" +
                    "</div>" +
                    "</div>";
            } else {
                var nom_mag = nom.replace(/'/g, "\\'");
                ligneListe +=
                    '<div class="availableTxt store_more" onclick="handleChange(\'' +
                    id +
                    "','" +
                    nom_mag +
                    "')\">" +
                    "<span>" +
                    txt_available_mag +
                    "</span>" +
                    "</div>" +
                    "</div>" +
                    "</div>";
            }
        } else {
            ligneListe += "</div>" + "</div>";
        }
    }

    if (!is_page_product) {
        ligneListe += '<div class="store_more"><a onclick="triggerStoreDatalayerPush(' + id + ')" href="' + path_relative_root + url + '">' + Translator.translate('afficher_horaires') + '</a>'
            + '</div>';
    }
    ligneListe += '<a onclick="createDataLayer(' + id + ')" href="https://maps.google.fr/maps?daddr=' + lat + ',' +  lng + '" class="button secondary btn_to_store" target="_blank">' + Translator.translate('store_route') + '</a>'

    ligneListe += "</div>" + "</div>" + "</div>";

    li.innerHTML = ligneListe;

    li.addEventListener("click", function () {
        google.maps.event.trigger(marqueur, "click");
    });
    
    if ($('#list-store').length > 0) {
        setTimeout(() => {
            $('#list-store').lionbars();
        }, 1000);
    }

    return li;
}

function creerMarqueur(
    point,
    nom,
    adresse,
    ville,
    cp,
    pays,
    horaire,
    telephone,
    email,
    site,
    image_mag,
    type_mag,
    cpt,
    magasin_id,
    url,
    distance,
    horaire_today,
    currently_open
) {
    var img = path_relative_root + "img/maps/pin.svg";
    var http = new RegExp("^http://", "g");

    // Origins, anchor positions and coordinates of the marker
    // increase in the X direction to the right and in
    // the Y direction down.
    var image = new google.maps.MarkerImage(
        img,
        // This marker is 17 pixels wide by 25 pixels tall.
        new google.maps.Size(17, 25),
        // The origin for this image is 0,0.
        new google.maps.Point(0, 0)
    );

    // The anchor for this image is the base of the flagpole at 0,32.
    //new google.maps.Point(10, 26));
    // Shapes define the clickable region of the icon.
    // The type defines an HTML <area> element 'poly' which
    // traces out a polygon as a series of X,Y points. The final
    // coordinate closes the poly by connecting to the first
    // coordinate.
    var marqueur = new google.maps.Marker({
        position: point,
        icon: image,
    });
    

    marqueur.set("magasin_id", magasin_id);
    nom = $.trim(nom);
    adresse = $.trim(adresse);
    ville = $.trim(ville);
    cp = $.trim(cp);
    pays = $.trim(pays);
    horaire = $.trim(horaire);
    telephone = $.trim(telephone);
    email = $.trim(email);
    site = $.trim(site);
    distance = Math.round(distance);
    var info_horaire_today = !horaire_today ? "" : horaire_today;
    var class_horaire_today = currently_open ? "store_open" : "store_close";
    var currently_open_info = currently_open
        ? Translator.translate("store_opened")
        : Translator.translate("store_closed");

    if (distance) {
        var ligneListe =
            '<div class="info_popup"><div class="name"><a href="' +
            url +
            '">' +
            nom +
            '</a><span class="dist">(' +
            distance +
            " km)</span></div>";
    } else {
        var ligneListe =
            '<div class="info_popup"><div class="name"><a href="' +
            url +
            '">' +
            nom +
            "</a></div>";
    }

    ligneListe +=
        '<div class="adresse">' +
        adresse +
        " - " +
        cp +
        " " +
        ville +
        "<br />" +
        pays +
        "</div>";

    if (telephone != "")
        ligneListe += '<div class="coord">' + telephone + "</div>";

    if (site != "")
        if (!http.test(site)) {
            ligneListe +=
                '<a href="http://' +
                site +
                '" class="site" target="_blank">' +
                site +
                "</a>";
        } else {
            ligneListe +=
                '<a href="' +
                site +
                '" class="site" target="_blank">' +
                site +
                "</a>";
        }

    if (horaire != "")
        if (class_horaire_today == "store_open") {
            ligneListe +=
                '<div class="horaire_title"><span class = "' +
                class_horaire_today +
                '">' +
                currently_open_info +
                "</span>" +
                info_horaire_today +
                '</div><div class="horaire_info">' +
                horaire +
                "</div>";
        } else {
            //If closed we don't show "info_horaire_today" since it displays "Fermer Fermé"
            ligneListe +=
                '<div class="horaire_title"><span class = "' +
                class_horaire_today +
                '">' +
                currently_open_info +
                '</span></div><div class="horaire_info">' +
                horaire +
                "</div>";
        }
    // Tooltip more infos & go to store link
    ligneListe += `<div class="store_localisation_container">
        <div class="btn_voir_magasin go_to_store"><a class="geolocalisation_link" target="_blank" href="https://maps.google.fr/maps?daddr=${point.lat()},${point.lng()}">${Translator.translate(
        "go_to_store"
    )}</a></div>
        <div class="btn_voir_magasin"><a href="${url}">${Translator.translate(
        "voir_magasin"
    )}</a></div>
        </div>`;

    google.maps.event.addListener(marqueur, "click", function () {
        // selected store coordinates
        var storePosition = new google.maps.LatLng(point.lat(), point.lng());
        // center map on marker
        maCarte.setCenter(storePosition);
        // map animation on marker switch
        maCarte.panTo(storePosition);

        if (!infowindow) {
            infowindow = new google.maps.InfoWindow();
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        } else {
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        }
    });

    var detail_store_panel = document.getElementById("store_id_dataLayer");
    detail_store_panel.textContent = magasin_id;

    markersArray.push(marqueur);

    return marqueur;

}

function addToMyFavorite(
    magasin_id,
    client_id,
    favorite,
    info_horaire_today,
    currently_open,
    horaire_today,
    class_horaire_today,
    stock,
    stockStatus
) {
    event.preventDefault();
    if (client_id == 0) {
        var connect_link = path_relative_root + create_link("connexion_login");
        alert(
            '<div class="box_txt_wishlist selected_mag"><h2>' +
                Translator.translate("add_store_login_error_title") +
                "</h2><br>" +
                Translator.translate("add_store_login_error_text") +
                '<br><button class="button primary" onclick="favStoreRedirectLogin()">' +
                Translator.translate("vgt_connexion") +
                " </button></div>"
        );
    } else {
        if ($(".favorite_store_js").length == 0) {
            var act = "add";
        } else {
            var act = "del";
        }

        $.ajax({
            url: path_relative_root + "ajax_manage_favorite_store.php",
            type: "post",
            dataType: "html",
            data:
                "act=" +
                act +
                "&magasin_id=" +
                magasin_id +
                "&client_id=" +
                client_id,
            success: function (response) {
                response = JSON.parse(response);
                var getCustomerFavoriteStore = document.querySelector(
                    "#customerFavoriteStore"
                );
                var customerFavoriteStore = false;
                if (getCustomerFavoriteStore) {
                    customerFavoriteStore = customerFavoriteStore.value;
                }
                var shopName = document.querySelector(".mag_name_fav");
                var shopStock = document.querySelector(".mag_stock_fav");
                var shopHours = document.querySelector(".mag_hours");
                var shopTown = document.querySelectorAll(".mag_ville");
                var shopStreet = document.querySelector(".mag_rue");
                var shopCountry = document.querySelector(".mag_pays");
                var shopCp = document.querySelector(".mag_cp");

                var noStoreSelected =
                    document.querySelector("#no_store_selected");
                var noStoreSelectedHidden = document.querySelector(
                    "#no_store_selected_hidden"
                );

                var shopList = document.querySelector(".liste_distributeur");

                var customerHeartFav = document.querySelector(
                    "#heartFav-" + magasin_id
                );
                var allHeartFav = document.querySelectorAll(".heartFav");

                var currentFavStore =
                    document.querySelector("#currentFavStore");
                var hiddenProductFavStore = document.querySelector(
                    "#hiddenProductFavStore"
                );
                var noFavShop = document.querySelector("#noFavShop");

                if (allHeartFav) {
                    for (var i = 0; i < allHeartFav.length; i++) {
                        allHeartFav[i].className = "heartFav";
                    }
                }
                if (hiddenProductFavStore && noFavShop) {
                    noFavShop.style.display = "none";
                    hiddenProductFavStore.style.display = "flex";
                }

                if (customerHeartFav) {
                    customerHeartFav.className = "heartFav";
                    customerHeartFav.className = "selected";
                }

                if (shopList) {
                    shopList.className += " withfav";
                }
                if (
                    noStoreSelected &&
                    noStoreSelectedHidden &&
                    !currentFavStore
                ) {
                    noStoreSelected.style.display = "none";
                    noStoreSelectedHidden.style.display = "block";
                }

                if (shopName) {
                    shopName.innerHTML = response.store.magasin_nom;
                }

                if (shopTown) {
                    for (var i = 0; i < shopTown.length; i++) {
                        var town = shopTown[i];
                        town.innerHTML = response.store.magasin_ville;
                    }
                }
                if (shopStreet) {
                    shopStreet.innerHTML = response.store.magasin_adresse;
                }
                if (shopCp) {
                    shopCp.innerHTML = response.store.magasin_cp;
                }
                if (shopCountry) {
                    shopCountry.innerHTML = response.store.magasin_pays;
                }
                if (stock != "null") {
                    shopStock.innerHTML = Translator.translate(
                        stock.slice(0, -3)
                    );
                    shopStock.setAttribute("data-status", stockStatus);
                }

                if (shopHours) {
                    if (
                        (info_horaire_today !== "" && currently_open) ||
                        (!currently_open && horaire_today)
                    ) {
                        shopHours.innerHTML =
                            '<span class="horaire_today"><span class="puce ' +
                            unescape(class_horaire_today) +
                            '"></span> ' +
                            unescape(info_horaire_today) +
                            "</span>";
                    } else {
                        shopHours.innerHTML = "";
                    }
                }

                var storeClient = document.getElementById(
                    "favShop-" + magasin_id
                );
                var allStore =
                    document.getElementsByClassName("choose_fav_label");
                var countAllStore = allStore.length;

                for (var i = 0; i < countAllStore; i++) {
                    allStore[i].innerHTML =
                        "<span>" +
                        Translator.translate("choose_as_favorite_store") +
                        "</span>";
                }

                if (response.status == "add") {
                    storeClient.innerHTML =
                        '<span class="favorite_store_js">' +
                        Translator.translate("actual_favorite_store") +
                        "</span>";
                } else if (response.status == "delete") {
                    storeClient.innerHTML =
                        "<span>" +
                        Translator.translate("choose_as_favorite_store") +
                        "</span>";
                }
            },
        });
    }
    return false;
}

// popup PDV
function showpdv() {
    var shade, modbox;
    $.ajax({
        type: "post",
        url: path_relative_root + create_link("ajax_search_shop"),
        success: function (res) {
            if (res) {
                shade = document.querySelector("#shad.modal_shade");
                modbox = document.getElementById("pdvBox");

                // Make sure modbox will show on top of shad
                $(shade).after(modbox);

                // Inject store locator into modbox
                $(".store_locator").html(res);

                // magasin_gmap.js
                initialize();

                // Show modbox and make sure closing routine won't fire more than once

                $(shade).off("click", closepdv);
                $(modbox).addClass("deployed");
                $(shade).addClass("actif");
                $(shade).on("click", closepdv);
                $("#pdv_load").removeClass("loading");
            }
        },
    });
}

// FAVORITE STORE
function addToMyFavorites() {
    var is_favorite_elem = document.getElementById("is_favorite");

    var self = $(this);
    var magasin_id = self.attr("data-magasinid");
    var client_id = self.attr("data-clientid");
    var action = self.attr("data-action");

    // Si l'action est de détruire alors on est dans favorites_stores et on souhaite supprimer le bloc en entier
    if (action == "destroy") {
        var act = "del";
    } else {
        // On est dans store_details et on doit recupérer le statut actuel du magasin pour savoir si on doit le supprimer ou l'ajouter
        if (is_favorite_elem.value == "1") {
            var act = "del";
        } else {
            var act = "add";
        }
    }

    $.ajax({
        url: path_relative_root + "ajax_manage_favorite_store_detail.php",
        type: "post",
        dataType: "html",
        data: "act=" + act + "&magasin_id=" + magasin_id,
        success: function (response) {
            if (response) {
                // Destruction du bloc
                if (action == "destroy") {
                    self.slideUp("slow", function () {
                        self.remove();
                    });
                } else {
                    if (act == "add") {
                        var libelle =
                            "<span>" +
                            Translator.translate("delete_to_favorites") +
                            "</span>";

                        is_favorite_elem.value = 1;
                    } else {
                        var libelle =
                            "<span>" +
                            Translator.translate("add_to_favorites") +
                            "</span>";

                        is_favorite_elem.value = 0;
                    }
                    self.html(libelle);
                }
            }
        },
    });
}

// affichelostpassword
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

// Alert stock send email
function sendMailAlertStock(type_alert, form, itm_rr_id) {

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val(),
        $type_alert = type_alert;

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();
    var inputEmailBis = document.getElementsByClassName('expressAlertStock');

    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {

        $('#mail_alerte_stock' + elem).addClass('inputErr');
        $(inputEmailBis).addClass('inputErr1');
    } else {

        $('#mail_alerte_stock' + elem).removeClass('inputErr');
        $(inputEmailBis).removeClass('inputErr1');

        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: $email,
                produit_id: $produit_id,
                taille_id: $taille_id,
                couleur_id: $couleur_id,
                type_alert: $type_alert
            },
            success: function (response) {
                $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();

                    if ($('body').hasClass('wishlist')) {
                        $('.bloc_add_alert' + elem + ' .alert_return').css('top', '-14px');
                }
            },
            fail: function () {
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

function addAllWpToCart()
{
    var i = 0;
    var wishlistProductId = "";
    var wp = $(".addToCartFromWishlist");
    for (i = 0; i < wp.length; i++) {
        wishlistProductId += "," + wp[i].getAttribute('data-id');
    }
    addWpToCart(wishlistProductId);
}

function removeWp(from)
{
    var wishlist_product_id = $(this).attr("data-id");
    var product_id = $(this).attr("data-productid");
    var current_elem = $(this);

    $.post(path_relative_root + 'ajax_remove_from_wishlist.php', {id: wishlist_product_id}, function (data) {

        if (typeof from !== "undefined" && from == "wishlist") {
            location.reload();
        } else {
            var nr;
            if (data.result == undefined) {
                data.result = $('.wish_liste_product .product_ctn').length;

            } else {
                nr = data.result.wishlistLength;
            }

            if (nr == 0) {
                $("#wishlist_top").removeClass('hasItem');
                $(".item.wishlist").hide();
            }

            // MAJ products in rayon
            if ($('a[data-productid = "' + product_id + '"]').length > 0) {
                $('a[data-productid = "' + product_id + '"]').removeClass("existToWishlistButton");
                $('a[data-productid = "' + product_id + '"]').data('wishlistproductid', '');
                $('a[data-productid = "' + product_id + '"]').attr('data-wishlistproductid', '');
            }

            var response_html = ajax_file(path_relative_root + 'ajax_show_wishlist.php');
            $("#wishlist_top").html(response_html);

            $(current_elem.parents('.product-link')).fadeOut(100, function () {

                current_elem.parents('.product-link').remove();
            });
        }
    });
}

function addWpToCart(wishlistProductId) {
    if (typeof wishlistProductId != "string") {
        wishlistProductId = wishlistProductId.toString();
    }
    var selectedProducts = wishlistProductId.split(',');
    $.post(path_relative_root + "ajax_add_panier_wishlist.php", {data: wishlistProductId}, function(resp) {
        var data_layer_products = window.data_layer_products;
        var dtl_products = [];
        if (resp == "ok") {
            if (Object.keys(data_layer_products).length > 0) {
                for (const property in data_layer_products) {
                    if (selectedProducts.indexOf(property) !== -1) {
                        dtl_products.push(data_layer_products[property])
                    }
                }
            }
            if (dtl_products.length > 0) {
                var dataLayer = window.dataLayer || []
                dataLayer.push({
                    "event": "addToCart",
                    "ecommerce": {
                        'currencyCode' : 'window.siteCurrency',
                        "add": {
                            "products": dtl_products
                        }
                    }
                })

            }
            document.location.href = path_relative_root + create_link('order_basket');
        } else {
            $('.lightbox .form_submit .button span').html(Translator.translate("close"));
        }
    });
}

function lightboxAlertStock(productId) {
    if (typeof productId === "undefined") {
        productId = '';
    } else {
        productId = '_' + productId;
    }

    var $taille_id = $("#tailleProd").val(),
        $couleur_id = $("#couleurProd").val();

    if ($taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }

        return;
    } else if ($couleur_id == '') {
        alert(translate('js_error_couleur'));
        return;
    }

    var isFormVisible = $('#btn_add_alert' + productId).find('.bloc_add_alert_form').is(':visible');
    var isMessageVisible = $('#btn_add_alert' + productId).find('.bloc_add_alert_confirmation').is(':visible');
    var isErrorVisible = $('#btn_add_alert' + productId).find('.bloc_add_alert_erreur').is(':visible');

    if (isFormVisible || isMessageVisible || isErrorVisible) {

        if (isFormVisible) {
            $('#btn_alert_stock' + productId).show();
            $('#btn_add_alert' + productId).slideDown();
        }

        if (isMessageVisible) {
            $('#btn_add_alert' + productId).find('.bloc_add_alert_confirmation').hide();
            $('#btn_add_alert' + productId).show();
        }

        if (iserrorVisible) {
            $('#btn_add_alert' + productId).find('.bloc_add_alert_erreur').hide();
        }
    } else {

        $('#bloc_add_alert_form' + productId).show();
        $('#btn_add_alert' + productId).hide();
        $('#btn_alert_stock' + productId).hide();
    }
}

function close_alert_form(productId) {
    var form_product_info = $('.product_info_wrapper .product_info');

    form_product_info.find('.bloc_add_alert_confirmation').hide();
    form_product_info.find('#btn_add_alert').show();
}

/**
 * Filter orders by type
 */
function show_orders_by_type(elementID, type, parentPath, reload) {

    var str_year = '';
    var str_reload = '';
    var year = $("#year").val();

    if (year != '') {

        str_year = '&year=' + year;
    }

    if (reload !== undefined) {

        str_reload = '&reload=true';
    }

    // Gestion de la surbrillance des onglets
    var i = 1;
    while (document.getElementById("com_onglet_" + i)) {

        if (type == i) {

            $("#com_onglet_" + i).addClass("actif");
        } else {

            $("#com_onglet_" + i).removeClass("actif");
        }
        i++;
    }

    var container = document.getElementById(elementID);

    //clearTimeout(time_out);
    if (container) {

        var response = ajax_file(parentPath + 'ajax_account_orders_by_type.php?type=' + type + str_year + str_reload);
        if (response) {

            if (reload !== undefined) {

                $('.content_commandes').html(response);
            } else {

                $(container).html(response);
            }
        } else {

            container.innerHTML = '';
        }
    }
}


function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    // on définir globalement l'id transporteur pour l'utiliser dans la création de l'etiquette
    global_id_transporteur = idTransporteur;

    var divId = 'popup_numero_retour';
    divId += '_' + (multiTransp ? idOrderTransp : idOrder);
    var formId = '#return_products_form';
    formId += '_' + (multiTransp ? idOrderTransp : idOrder);

    openMultiShad(divId);
}

// Close 2nd return popup after ajax loaded
function close_popup_return(obj_elem) {
    $("#shad").hide();
    $("#popup_numero_retour_" + obj_elem).hide();
    location.reload();
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, idOrderTransp, fromCreateEtiquette) {
    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $('#return_products_form_' + idCmdOrder).serialize();
        data += '&idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour + '&send_mail=true&choix=no_impression';
    } else {
        data = 'idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour;
    }

    $.ajax({
        url: path_relative_root + 'ajax_create_etiquette.php',
        type: 'post',
        data: data,
        success: function (res) {
            if (res.substr(0, 5) == 'false') {
                alert(Translator.translate('js_error_return_label'));
            } else {
                // closeMultiShad('popup_numero_retour');
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    var response = ajax_file(path_relative_root + 'ajax_return_address.php?order_id=' + idCmdOrder + '&return_number=' + numRetour);

                    if (response !== '') {
                        $('.popup_numero_retour').html(response);
                        // add a title on return & return coupon popup
                        $("<div class='return_popup_title'>" + Translator.translate('return_pop_title') + "</div>").insertBefore("#printPdf");
                    } else {
                        location.reload();
                    }
                }
            }
        }
    });
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp, idOrderTransp, numRetour) {

    var hasReturn = false;
    // Check if at least one product has been selected for return
    $(".return_quantity").each(function () {
        if (hasReturn) return;

        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');
        // If the quantity is greater than 0 and the return reason is not 0 and the product is not disabled, go to the next step
        if ((($(this).val() > 0) && (return_choice.val() != '0')) && !$(this).is(':disabled')) {
            hasReturn = true;
            // Remove the error message if there is one
            $('.errorSelectProduct').removeClass('enabled');
        }
    });

    // If no product has been selected, display an error message
    if (!hasReturn) {
        $('.errorSelectProduct').addClass('enabled');
        return;
    }

    var numRetour = "";
    if (typeof idOrderTransp != "undefined")
        numRetour = ajax_file(
            parentPath +
            "ajax_num_etiquette.php?idOrder=" +
            idOrder +
            "&multiTransp=" +
            multiTransp +
            "&idOrderTransp=" +
            idOrderTransp
        );
    else
        numRetour = ajax_file(
            parentPath +
            "ajax_num_etiquette.php?idOrder=" +
            idOrder +
            "&multiTransp=" +
            multiTransp
        );


    if (numRetour != "false") {

        var transporteur = '';
        // Dans le cas du multi transporteur on définie les bloc qui doive s'afficher
        if (multiTransp == 1) {
            transporteur = '_' + global_transporteur_id;
        }
        var commentaireRetourObjet = "";

        if (document.getElementById("commentaireRetourDiv" + transporteur))
            commentaireRetourObjet = document.getElementById("commentaireRetourDiv").value;

        if (multiTransp !== 1) {
            if (document.getElementById("commentaireRetour") !== null) {
                commentaireRetourObjet = document.getElementById("commentaireRetour").value;
            }
        }
        showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, false, idOrderTransp, true);
    }
}

function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");
    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function () {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                //window.setTimeout(checkAllForm, 1000, tabError, 0, path_relative_root, id_form);
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}
function updatePanier(idProduit, i, paniervirtuelId) {
    var couleurs = document.getElementById("couleurProd" + i);
    var tailles = document.getElementById("tailleProd" + i);
    var qte = document.getElementById("qteProd" + i);

    var idCouleur = 0;
    var idTaille = 0;

    if (couleurs) idCouleur = couleurs.value;
    if (tailles) idTaille = tailles.value;

    ajax_file(
        "ajax/ajax_upd_panier.php?idprod=" +
        idProduit +
        "&idcouleur=" +
        idCouleur +
        "&idtaille=" +
        idTaille +
        "&qte=" +
        qte.value +
        "&panierId=" +
        paniervirtuelId
    );
}
function newUpdatePanier(idProduit, i, paniervirtuelId, prixU, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur) {

    if (i != '') {

        i = '_' + i;

        prixU = $('#prixU' + i).val();
    }

    var couleurs = $('#couleurProd' + i);
    var tailles = $('#tailleProd' + i);
    var res = '';
    var html_err = '';
    var flagError = false;

    if ($('#lightbox_achat_express').length > 0) {

        from_range = true;
    } else {

        from_range = false;
    }

    html_err = '<div class="txt_alert"></div>';
    html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

    if(tailles.length && tailles.val() == '') {

        $('#sizeerror').slideDown();

        return false;
    } else if (couleurs && couleurs.val() == '') {

        res = ajax_file(path_web+'ajax_sprintf.php?arg1=js_error_couleur');
        flagError = true;
    } else if (document.getElementById('qteProd' + i) && document.getElementById('qteProd' + i).value == '') {

        res = ajax_file(path_web + 'ajax_sprintf.php?arg1=js_error_quantity');
        flagError = true;
    }

    if (flagError) {

        alertV2(res);

        return false;
    } else {

        var qte = 0;

        if ($('#qteProd' + i)) {
            qte = $('#qteProd' + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != 'none') {
            idCouleur = couleurs.val();
        }

        if (tailles && tailles.val() != 'none') {
            idTaille = tailles.val();
        }
        
        if (idCouleur === undefined) {
            idCouleur = idElCouleur;
        }

        if (idTaille === undefined) {
            idTaille = idElTaille;
        }

        var array_response = [];
        var response = ajax_file(path_web + 'ajax_upd_panier.php?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId);

        if (response) {

            array_response = response.split("//");

            var designation = array_response[0];

            if ( titreObjet.match('^[0-9]') ) {

                if ( designation == 1 ) {

                    designation += ' lot de';

                } else {

                    designation += ' lots de';
                }
            }

            designation += ' ' + titreObjet;

            if (document.getElementById(idElTaille)) {

                if (document.getElementById(idElTaille).selectedIndex) {

                    designation += ' - Taille ' + document.getElementById(idElTaille).options[document.getElementById(idElTaille).selectedIndex].text;

                } else if (lastTaille != '') {

                    designation += ' - Taille ' + lastTaille;
                }
            }

            if ($('[name="' + idElCouleur + '"]').length > 0) {

                if ($('[name="' + idElCouleur + '"]:checked').length > 0) {

                    designation += ' - ' + $('[name="' + idElCouleur + '"]:checked').get(0).nextElementSibling.title.trim();
                } else if (lastCouleur != '') {

                    designation += ' - ' + lastCouleur;
                }
            }

            array_response[0] = designation;

            // SI il n'y a plus de stock
            if (array_response[1] == 'false') {
                $('#size_' + idTaille).addClass('disabled');
                alertV2(Translator.translate('product_no_stock_2'));
                return false;
            }
        }

        return array_response;
    }
}

// Permet le non rafraichissement d'une page en exploitant l'objet XHR
function ajax_file(fichier) {
    if(window.XMLHttpRequest) // FIREFOX-
         xhr_object = new XMLHttpRequest();
    else if(window.ActiveXObject) // IE
         xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else
         return(false);
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if(xhr_object.readyState == 4) return(xhr_object.responseText);
    else return(false);
}

function ajax_file_post(url, params) {
   if(window.XMLHttpRequest) // FIREFOX-
       xhr_object = new XMLHttpRequest();
   else if(window.ActiveXObject) // IE
       xhr_object = new ActiveXObject("Microsoft.XMLHTTP");

   xhr_object.open("POST", url, false);
   xhr_object.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
   xhr_object.send(params);

   if(xhr_object.readyState == 4 && xhr_object.status == 200) {
      return xhr_object.responseText;
   }
}

function ajax_file_XML(fichier) {
    if(window.XMLHttpRequest) // FIREFOX-
         xhr_object = new XMLHttpRequest();
    else if(window.ActiveXObject) // IE
         xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else
         return(false);
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if(xhr_object.readyState == 4) return(xhr_object.responseXML);
    else return(false);
}

function switchVisu() {

    var src_format = $('#list_item').length && $('#list_item').hasClass('bydefault') ? 'prodfp' : 'medium';
    var dest_format = $('#list_item').length && $('#list_item').hasClass('bydefault') ? 'medium' : 'prodfp';

    $('.swiper-lazy').each(function () {
        if (typeof ($(this).attr('data-src')) != 'undefined') {
            $(this).attr('data-src', $(this).attr('data-src').replace(src_format, dest_format));
        }
        if (typeof ($(this).attr('src')) != 'undefined') {
            $(this).attr('src', $(this).attr('src').replace(src_format, dest_format));
        }
    });

    $('#list_item .productSwiper img').each(function () {
        if (typeof ($(this).attr('src')) != 'undefined') {
            $(this).attr('src', $(this).attr('src').replace(src_format, dest_format));
        } else {
            $(this).attr('data-src', $(this).attr('data-src').replace(src_format, dest_format));
        }
    });

    $('#list_item .productSwiper .ill_img').each(function () {
        if (typeof ($(this).attr('data-src')) != 'undefined') {
            $(this).attr('data-src', $(this).attr('data-src').replace(src_format, dest_format));
        }
    });
}

function viewBy(type, old_type) {
    const list_item = $('#list_item');
    const view_changer = $('#viewChanger');
    
    if (list_item.length && list_item.hasClass(old_type)) {
        list_item.removeClass(old_type).addClass(type);
        view_changer.find('.' + type).addClass('actif');
        view_changer.find('.' + old_type).removeClass('actif');
        
        switchVisu();
    }

    const nbByRow = (type === 'bydefault') ? 3 : 6;
    createCookie("nb_produits", nbByRow, 1);

    setTimeout(function () {
        $('.productSwiper').each(function () {
            if ($(this).hasClass('swiper-container-initialized')) {
                this.swiper.update();
            }
        });
    }, 400);
}

/*
 * Tous ce qui concerne la gestion des wishlist (fiche produit et page wishlist)
 * product_right.php, wishlist_index.php
 */

/**
 * Dans la fiche produit. Le bouton ajouter à la wishlist. (product_right.php)
 */
function addToWishlist() {
    var btn = $(this);
    var form_prod = $(this).parents('form');
    var idElt = btn.data('idelt');
    var productId = (btn.data('productid') ? btn.data('productid') : $("#produit_id").val());
    var colorId = form_prod.find('#couleurProd_' + idElt).length == 1 ? $('#couleurProd_' + idElt).val() : $('#couleurProd').val();
    var sizeId = form_prod.find('#tailleProd_' + idElt).length == 1 ? $('#tailleProd_' + idElt).val() : $('#tailleProd').val();
    var datas = {
        product_id: productId,
        color_id: colorId,
        size_id: sizeId,
        need_color_id: 'true',
        need_size_id: 'true'
    };

    if (!btn.hasClass('existToWishlistButton')) {
        $.ajax({
            url: path_relative_root + "ajax_add_to_wishlist.php",
            type : 'post',
            data: datas
        })
            .done(function(resp) {
                var $alertboxLogin = $("#abox");
                var $alertboxBtns = $("#abox .wrap_btn_abox");
                $alertboxLogin.addClass('box_wishlist');

                if (resp.error) {

                    var message = resp.result.error_message ? resp.result.error_message : resp.message;

                    if (resp.message === "login_required") {
                        var wishlistSubmit = $('.form_submit.form_submit_wishlist', $alertboxLogin);

                        if (wishlistSubmit.length === 0) {
                            $alertboxBtns
                                .append($(
                                    '<div class="form_submit form_submit_wishlist">' +
                                    '<button class="button dark"  href="javascript:void(0)" onclick="wishlistRedirectLogin(\'' + productId + '\');">' +
                                    '<span>' +
                                    resp.result.btn_connexion +
                                    '</span>' +
                                    '</button>' +
                                    '</div>')
                                );
                        } else {
                            wishlistSubmit.css('display', 'flex');
                        }
                        // On cache le bouton original
                        $('#abox .form_submit.original').css('display', 'none');
                    }
                    if (resp.result.error_message) {
                        alertV2(Translator.translate(resp.result.error_message));
                    } else {
                        $('#sizeerror').addClass('active');
                        $('.disabledBtnTooltip').addClass('actif');

                        setTimeout(function () {
                            $('.disabledBtnTooltip').removeClass('actif');
                        }, 2000);
                    }
                } else {
                    //evenement DataLayer
                    if (window.dataLayer !== undefined) {
                        window.dataLayer.push({
                            'event': 'addToWishlist',
                            'products': {
                                'product_id': productId,
                            }
                        });
                    }
                    
                    var dl_v3_obj = resp.result.dlv3_wishlist_item;

                    if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && Object.keys(dl_v3_obj).length > 0) {
                        DataLayerTrigger.addToWishList(dl_v3_obj['items'], dl_v3_obj['actionField'])
                    }

                    btn.addClass("existToWishlistButton");
                    btn.data('wishlistproductid', resp.result.wishlist_product_id);
                    btn.attr('data-wishlistproductid', resp.result.wishlist_product_id);

                    if (typeof wishlist !== 'undefined') {
                        wishlist = new Array();
                        wishlist.push(resp.result.wishlist_row_array);
                    }

                    var response_basket = ajax_file(path_relative_root + 'ajax_show_wishlist.php');
                    
                    $(".block_top_links #wishlist_top").html(response_basket);
                    addToWishlistUpdateHeader(resp.result.wishlist_size);
                }
            });
    } else {
        datas.remove = true;

        $.ajax({
            url: path_relative_root + "ajax_remove_from_wishlist.php",
            type: 'post',
            data: datas
        })
            .done(function (resp) {
                if (!resp.error) {
                    btn.removeClass("existToWishlistButton");
                    btn.data('wishlistproductid', '');
                    btn.attr('data-wishlistproductid', '');

                    var response_html = ajax_file(path_relative_root + 'ajax_show_wishlist.php');

                    $("#wishlist_top").html(response_html);
                    addToWishlistUpdateHeader(resp.result.wishlist_size);
                }
            });
    }
}

/**
 * Permet d'ajour un produit à la wishlist via le rayon (ou la recherche)
 * Utilisation : app/_components/product/achat_express.php/achat_express_search.php
 */
function addToWishlistRay() {

    // On utilise le même que dans la fiche produit car cela fait la même chose
    // mais on donne un nom différent au cas où
    addToWishlist.call(this);
}

function wishlistRedirectLogin(div_id) {
    $.get(path_relative_root + "ajax_add_to_wishlist.php?login_required=1", function () {
        document.location.href = path_relative_root + create_link('connexion_login') + "?from=front&div_id=" + div_id;
    });
}

function addToWishlistUpdateHeader(nr) {
    if (nr === 0) {
        $(".wrapper_menu_compte_container .item.wishlist").hide();
        $("#number_wishlist_top").html('(0)');
    } else {
        $('.wrapper_menu_compte_container .wishlist_off').removeClass('wishlist_off');
        $(".wrapper_menu_compte_container .item.wishlist").show();
        $(".block_top_item.my_account .wishlist a").html(Translator.translate('ma_wishlist')+ ' (' + nr + ')');
    }
}

function updateCartElements () {
    var nr = $(".cart_main_table.wishlist.dropper .cart_product_line.draggableElem").length;
    addToWishlistUpdateHeader(nr);
}

function scanForChanges() {
    var totalPrice = 0;
    selectedProducts = []; // global
    $(".checkbox_select_product").each(function () {
        var element = $(this);
        var id = element.attr("data-id");

        if (element.is(':checked')) {
            var price = parseFloat(element.attr('data-price'));

            if (price) {
                totalPrice += price;
            }

            selectedProducts.push(id);
            if (element.attr("pointscadeau")) {
                totalPoints += parseInt(element.attr("pointscadeau"));
            }
        }
    });
    var txt = "",
        text = "";
    var priceSpan = $("#total_price");
    switch (selectedProducts.length) {
        case 0:
            txt = $texts[0] + ' :';
            priceSpan.html('0<span class="devise">&euro;</span><sup></sup><span class="decimal_price"></span>');
            break;
        case 1:
            text = $texts[1];
        default:
            if (text === '')
                text = $texts[2];

            var pricefloor = Math.floor(totalPrice);

            var decimal = '';
            if (pricefloor !== totalPrice) {
                decimal = Math.round((totalPrice - pricefloor) * 100);
                decimal = '' + decimal;
                if (decimal.length === 1) {
                    decimal = decimal + '0';
                }
            }

            priceSpan.html(pricefloor + '<span class="devise">&euro;</span><sup></sup><span class="decimal_price">' + decimal + '</span>');
            txt = '<span class="wishlist_count">' + selectedProducts.length + '</span> ' + text + ' :';
    }

    $(".txt", "#nrArticlesSelected").html(txt);

}

function addProductToCart(product_id, color_id, size_id) {
    $.post(path_relative_root + create_link('ajax_wishlist_add_product_to_cart'),
        {
            product_id: product_id,
            color_id: color_id,
            size_id: size_id
        }, function (resp) {
            if (resp === "ok") {
                document.location.href = path_relative_root + create_link('order_basket');
                if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && product_id !== null && product_id !== undefined) {
                    DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, product_id, "add_to_cart");
                } else {
                    var data_layer_products = window.data_layer_products;
                    var dtl_products = [];

                    if (Object.keys(data_layer_products).length > 0) {
                        for (const property in data_layer_products) {
                            if (property !== product_id) {
                                dtl_products.push(data_layer_products[property])
                            }
                        }
                    }
                    if (window.dataLayer !== undefined && dtl_products.length > 0) {
                        window.dataLayer.push({
                            'event': 'addToCart',
                            'ecommerce': {
                                'currencyCode': 'EUR',
                                'add': {
                                    'products': dtl_products
                                }
                            }
                        });
                    }
                }
            } else {
                alertV2(Translator.translate('error_add_basket'));
            }
        });
}

function addCustomizedProductToCart(pc_id) {
    var errors = [];

    //get data
    var customizationData = {};
    $.ajax({
        url: path_relative_root + create_link('ajax_configurator_get_configuration'),
        type: 'post',
        async: false,
        data: { pc_id: pc_id },
        dataType: 'json',
        success: function(response) {
            if (response.success) {
                customizationData = response.json;
            }
        }
    });

    // add main product to basket
    if (customizationData.steps.length) {
        //send main product
        customizationData.product.configurator_product = false;
        customizationData.product.reinit_basket_virtuel_parent_id = true;
        customizationData.product.set_session_basket_as_parent = true;
        var response = sendProductToBasket(customizationData.product);
       // if main product added, add other products
        if (response.error !== undefined && response.error !== 0) {
            if (response.error.msg !== '' && response.error.msg !== undefined) {
                alertV2(Translator.translate('error_insert_basket') + '<br>' + (errors.join('<br>')));
            }
        } else {
            $.each(customizationData.steps, function (index, customizationData) {
                $.each(customizationData.customization, function (indexProduct, product) {
                    product.id = product.product_id;
                    product.configurator_product = true;
                    var response = sendProductToBasket(product);
                    // On regarde si il y a eu une erreur dans l'ajout (cas plus de stock)
                    if (response.error !== undefined) {
                        if (response.error.msg !== '' && response.error.msg !== undefined) {
                            errors.push(response.error.msg);
                        }
                    }
                });
            });

            if(errors.length) {
                alertV2(Translator.translate('error_insert_basket') + '<br>' + (errors.join('<br>')));
            }
            else {
                //update produit_configurator_data
                $.ajax({
                    url: path_relative_root + create_link('ajax_configurator_update_virtual_basket_id'),
                    type: 'post',
                    async: false,
                    data: {
                        pc_id: pc_id
                    },
                    dataType: 'json',
                    success: function(response) {
                        if (response.success) {
                            document.location.href = path_relative_root + create_link('order_basket');
                        }
                        else {
                            alertV2(Translator.translate('error_add_basket'));
                        }
                    }
                });
            }
        }
    }
}

function sendProductToBasket(product) {
    var response = {};
    $.ajax({
        url: path_relative_root + "ajax_upd_panier.php",
        type: "post",
        dataType: 'json',
        async: false,
        data: {
            configurator: true,
            idprod: product.id,
            idcouleur: product.color_id,
            idtaille: product.size_id,
            panierid: 0,
            basket_virtuel_parent_id: product.basket_virtuel_parent_id,
            qte: 1,
            configurator_product: product.configurator_product,
            dataTypeReturn: 'json',
            reinit_basket_virtuel_parent_id: product.reinit_basket_virtuel_parent_id != undefined ? product.reinit_basket_virtuel_parent_id : false,
            set_session_basket_as_parent: product.set_session_basket_as_parent != undefined ? product.set_session_basket_as_parent : false,
        },
        success: function (res) {
            response = res;
        }
    });
    return response;
}

/**
 * Envoie le formulaire de la lightbox wishlist avec gestion des erreurs
 * Utilisation : wishlist_index.php
 */
function sendWishListForm(form) {

    var friendMail = form.elements['friend_email'].value;
    var inputMailElm = $(form.elements['friend_email']);

    if (friendMail.length > 0) {

        inputMailElm.removeClass('inputErr');
        var data = {
            'to_mails': friendMail,
            'to_message': 'Wishlist',
            'your_name': form.elements['customer_firstname'].value,
            'your_mail': form.elements['customer_email'].value
        };

        var error = false;
        var reportMessage = '';
        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist_form'),
            data: data
        }).done(function (response) {

            $('.zone_text').css({border: ''});

            if (response.split(',')[0] === 'ok') {
                if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null) {
                    dataLayer.push({
                        "event": "sendWish_click",
                    });
                }
                var sing = (response.split(',').length > 2) ? 'sendfriend_ok_plur' : 'sendfriend_ok_sing';
                reportMessage = Translator.translate(sing);
            }
            if (response === '1' || response === '2') {
                error = true;
                reportMessage = Translator.translate('sendfriend_error_mail_friends_invalid');
            } else if (response === '3') {
                error = true;
                reportMessage = Translator.translate('sendfriend_error_no_message');
            } else if (response === '4') {
                error = true;
                reportMessage = Translator.translate('sendfriend_error_no_name');
            } else if (response === '5' || response === '6') {
                error = true;
                if (response === '5') {
                    reportMessage = Translator.translate('sendfriend_error_no_my_mail');
                } else {
                    reportMessage = Translator.translate('sendfriend_error_my_mail_invalid');
                }
            } else if (response === 'token_error') {
                error = true;
                reportMessage = Translator.translate('error_token_security');
            } else if (response.split(',')[0] === '7') {
                error = true;
                reportMessage = Translator.translate(sing);
            }

            var emailReportElm = $('.email_wishlist_report');
            emailReportElm.html(reportMessage);
            if (error) {
                inputMailElm.parent('label').addClass('w-has-error');
                inputMailElm.addClass('inputErr');
                emailReportElm.addClass('error');
            } else {
                emailReportElm.removeClass('error');
                inputMailElm.val('');
            }
            emailReportElm.removeClass('cache');
        });

    } else {
        inputMailElm.parent('label').addClass('w-has-error');
        inputMailElm.addClass('inputErr');
    }

    return false;
}

/**
 * Utiliser pour ajouter un magasin dans ses favoris (boutique_details)
 */
function addToMyFavorites() {

    var is_favorite_elem = document.getElementById('is_favorite');
    var self = $(this);
    var magasin_id = self.attr('data-magasinid');
    var client_id = self.attr('data-clientid');
    var action = self.attr('data-action');

    // Si l'action est de détruire alors on est dans favorites_stores et on souhaite supprimer le bloc en entier
    if (action == 'destroy') {
        var act = 'del';
    } else {
        // On est dans store_details et on doit recupérer le statut actuel du magasin pour savoir si on doit le supprimer ou l'ajouter
        if (is_favorite_elem.value == '1') {
            var act = 'del';
        } else {
            var act = 'add';
        }
    }

    $.ajax({
        url: path_relative_root + 'ajax_manage_favorites_stores.php',
        type: 'post',
        dataType: 'html',
        data: 'act=' + act + '&magasin_id=' + magasin_id,
        success: function (response) {

            if (response) {

                // Destruction du bloc
                if (action == 'destroy') {
                    self.slideUp('slow', function () {
                        self.remove();
                    });
                } else {
                    if (act == 'add') {
                        var libelle = '<span>' + Translator.translate('delete_to_favorites') + '</span>';

                        is_favorite_elem.value = 1;
                    } else {
                        var libelle = '<span>' + Translator.translate('add_to_favorites') + '</span>';

                        is_favorite_elem.value = 0;
                    }
                    self.html(libelle);
                }
            }
        }
    });
}

// Product

var save_id = 0;
var cpt_save = 0;
var from_range = false;  //for wishlist

function addEvent(elem,event,name_function) {

    if(document.all) elem.attachEvent("on"+event,name_function);
    else elem.addEventListener(event,name_function,false);
}

function delEvent(elem,event,name_function) {

    if(document.all) elem.detachEvent("on"+event,name_function);
    else elem.removeEventListener(event,name_function,false);
}

// empeche les evenements de remonter dans l'arbre

function doNothing(e) {

if (document.all) {
  if (!e) var e = window.event;
  e.cancelBubble = true;
 }
 else {
    e.stopPropagation();
 }
 return false;
}
// popup guide des tailles
function showsizeguide(){
    var elname = 'sizeguidebox';

    var actualposition = document.documentElement.scrollTop;
    var modbox = document.getElementById(elname);
    var shade = document.getElementById('shade');
    modbox.style.position = "absolute";
    shade.style.display="block";

    addEvent(shade,'click',closesizeguide);

    addEvent(document.getElementById('menu_sizeguide'), 'click', doNothing);

    modbox.style.display="block";

    window.scrollTo(0,actualposition);
}

// popup alerte taille
function showAlertSizeBox(taille){

    var alertSizeBoxText = document.getElementById('alertSizeBoxText');
    alertSizeBoxText.innerHTML = taille;
    openMultiShad('alertSizeBox');
}

function closesizeguide() {
    var elname = 'sizeguidebox';
    var shade = document.getElementById('shade');
    var modbox = document.getElementById(elname);
    modbox.style.display="";

    delEvent(shade,'click',closesizeguide);
    delEvent(modbox,'click',closesizeguide);
    delEvent(document.getElementById('menu_sizeguide'), 'click', doNothing);
    closemodal();
}

function showmodal(produit_id){
    var actualposition = document.documentElement.scrollTop;
    var modbox = document.getElementById('modbox');
    var cmodbox = document.getElementById('cmodbox');
    var panmodbox = document.getElementById('panmodbox');
    var shade = document.getElementById('shade');
    document.body.style.overflow='hidden';

    cmodbox.innerHTML='';
    panmodbox.innerHTML = '';

    modbox.style.marginTop = (actualposition-260)+"px";
    shade.style.display="block";
    modbox.style.display="block";
    document.getElementsByTagName("html")[0].style.overflow = "hidden";

    window.scrollTo(0,actualposition);
}

var addToCartTmpl;

function buildItemAddedModbox(id_produit, is_not_produit) {

    var form = document.getElementById('prod_info_' + id_produit);
    var closing_method = (is_not_produit == 'true') ? 'window.location.reload()' : 'closeMultiShad(\'modbox\')';

    if (document.getElementById('nbr_visu_0')) {

        var itm_vis = $('img', '#nbr_visu_0').attr('src').replace('/mini/', '/large/');
    }

    var itm_name = $('#itm_name', form).get(0).textContent.trim();

    if ($('#itm_feat', form).length > 0) {

        var itm_feat = $('#itm_feat', form).get(0).textContent.trim();
    }

    var itm_price = $(".pricetag:first", form).html();

    if ($(".pricetag:first + .pricetag", form).length) {

        var itm_fullprice = $(".pricetag:first + .pricetag", form).html();
    }

    if (form) {

        var itm_length = parseFloat(form.elements.namedItem('qteProd').value);
        var itm_color = form.elements.namedItem('couleurProd').value;
        var itm_size = form.elements.namedItem('tailleProd').value;

        if (form.getValue('produit_lot_nb', parseFloat) > 1 || itm_length > 1) {

            itm_length += ' ' + Translator.translate('items');
        } else {

            itm_length += ' ' + Translator.translate('item');
        }

        if (itm_length == 1) {

            itm_length += ' ' + Translator.translate('added_to_cart_sn');
        } else if (form.getValue('produit_lot_nb') == '1') {

            itm_length += ' ' + Translator.translate('added_to_cart_pl');
        }

        itm_color = $("input[name=itm_color]", form).filter("[value=" + itm_color + "]").next("label").attr("title").trim();
        itm_size = $("input[name=itm_size]", form).filter("[value=" + itm_size + "]").next("label").attr("title").trim();
    }

    $('#shade').after($('#modbox'));

    document.getElementById('modbox').innerHTML = Mustache.render(addToCartTmpl, {
        closing_method: closing_method,
        itm_vis: itm_vis,
        itm_name: itm_name,
        itm_feat: itm_feat,
        itm_price: itm_price,
        itm_fullprice: itm_fullprice,
        itm_length: itm_length,
        itm_color: itm_color,
        itm_size: itm_size
    });
    // Si c'est la premiere fois on crée l'image et ensuite on l'insere a la suite
    // C'est pour éviter que le serveur appel une image qui n'existe pas
    if ( $('#modbox_visu').length > 0 ) {
        var span = $('#modbox_visu');
        // Creation de l'image
        var img = $('<img>', {
            src : span.data('src'),
            alt : span.data('alt')
        });
        $('#modbox_visu').after(img);
        $('#modbox_visu').remove();
    }
}

/**
 * Lancer de l'ajout au panier
 * @param products
 * @param type
 * @param root
 * @param id_produit
 * @param is_not_produit
 */
function newshowmodal(products, type, root, id_produit, is_not_produit) {
    cartShow();
}

function showsendfriendmodal(product_id, path_web, type) {

    var elname = (type === 'look_basket') ? 'modbox' : 'sendfriendbox';
    var actualposition = document.documentElement.scrollTop ;

    if (type !== 'look_basket') {

        document.getElementById('product_sendfriend_nok').innerHTML = '';
        document.getElementById('product_sendfriend_ok').innerHTML = '';
        document.getElementById('product_sendfriend_nok').style.display = 'none';
        document.getElementById('product_sendfriend_ok').style.display = 'none';

        openMultiShad('sendfriendbox');

    } else {

        openMultiShad(elname);
        reloadLookparts();
        $('.loader', '#bloc_add_basket').hide();
    }
}

function closesendfriendmodal() {

    closeMultiShad('sendfriendbox');

    document.getElementById('sendfriend_alert_mail').style.display="none";
    document.getElementById('sendfriend_form_to').style.display="block";
    document.getElementById('sendfriend_form_froms').style.display="block";
    document.getElementById('sendfriend_form_buttons').style.display="block";
    document.body.style.overflow='';
    document.getElementsByTagName("html")[0].style.overflow = "";

    if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1 ) {

        var svn=document.getElementsByTagName("SELECT");
        for (a=0;a<svn.length;a++){
            svn[a].style.visibility="visible";
        }
    }
}

function closesendfriendmodalfromconfirm() {

    closeMultiShad('sendfriendbox');

    document.body.style.overflow='';
    document.getElementsByTagName("html")[0].style.overflow = "";
    document.getElementById('sendfriend_form_to').style.display="block";
    document.getElementById('sendfriend_form_froms').style.display="block";
    document.getElementById('sendfriend_form_buttons').style.display="block";
    document.getElementById('sendfriend_alert_mail').innerHTML="";
    document.getElementById('sendfriend_alert_mail').style.display="none";

    if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1 ) {

        var svn=document.getElementsByTagName("SELECT");
        for (a=0;a<svn.length;a++){
            svn[a].style.visibility="visible";
        }
    }
}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {
    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var loader;

    var form_id = form_elm.getAttribute('id');
    var fk_produit_id = $("#" +  form_id).find('.prodSize').filter(':checked').data('produitid');

    if(!is_achat_express)
        evt.preventDefault();

    if (from === undefined) {

        from = '';
    }

    if (is_not_produit == true) {

        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {

        loader = $(this.nextElementSibling);

        loader.addClass("loading");

        window.setTimeout(function() {
            loader.removeClass("loading");
        }, 2000);
    } else {

        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {

        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {

        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id, fk_produit_id);
    }
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id, fk_produit_id) {

    var id = '';
    var prix_id = 'prixU';

    if (itm_rr_id === undefined) {
        itm_rr_id = '';
    }

    if (from == 'basket' || is_not_produit == true || is_achat_express) {

        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {

            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var send_produit_id = produit_id;
    if (typeof fk_produit_id !== 'undefined' && fk_produit_id != '') {
        send_produit_id = fk_produit_id;
    }

    var prix = '';
    if (document.getElementById(prix_id)) {
        prix = document.getElementById(prix_id).value;
    }

    var array_panier = newUpdatePanier(send_produit_id, suff, panier_id, prix, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false) {

        if (from == 'basket') {

            location.reload();

            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {

            type = 'err';
        }

        omnitureEvent = '';

        if (array_panier[1] == 1) {

            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
        } else {

            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        if (loader) {

            loader.parent().removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {

            void(s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void(s.t());
        }

        if (array_panier.length >= 0 && array_panier[5] !== undefined) {
            var dl_objet = array_panier[5];
            if (is_achat_express) {
                var new_dl_objet = JSON.parse(dl_objet)
                new_dl_objet.ecommerce.is_achat_express = true;
                dl_objet = JSON.stringify(new_dl_objet);

            }
            pushIntoDatalayer(dl_objet);
        }

        if (typeof dataLayer_v3 !== 'undefined' ) {
            if (array_panier.length >= 0 && array_panier[6] !== undefined) {
                var dlv3_objet = JSON.parse(array_panier[6]);
                DataLayerTrigger.addToCart(dlv3_objet['items'], dlv3_objet['actionField'], is_achat_express);
            }
        }

        // Mise à jour du panier top
        var response_basket = ajax_file(path_web + 'ajax_show_basket.php');

        $("#cart_top").html(response_basket);
        
        cartShow();
        
        if ($('#cart_list .product-link').length) {
            $('#cart_list').overlayScrollbars({
                overflowBehavior: {
                    x: 'hidden',
                },
            });
        }

        if ($('#cart_list_wish .product-link').length) {
            $('#cart_list_wish').overlayScrollbars({
                overflowBehavior: {
                    x: 'hidden',
                },
            });
        }

        $( "#cart_top" ).on('mouseenter touchstart', function(evt) {
            if ($('#cart_list .product-link').length) {
                $('#cart_list').overlayScrollbars({
                    overflowBehavior: {
                        x: 'hidden',
                    },
                });
            }
        });

        $( "#wishlist_top" ).on('mouseenter touchstart', function(evt) {
            if ($('#cart_list_wish .product-link').length) {
                $('#cart_list_wish').overlayScrollbars({
                    overflowBehavior: {
                        x: 'hidden',
                    },
                });
            }
        });

        $("#lightbox_achat_express, .lightbox_achat_express, #shad").hide();

        newshowmodal(produit_id, is_not_produit);
    } else {

        if (loader) {

            loader.parent().removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    eval(toEval);

    if (array_panier != false) {

        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

            var svn = document.getElementsByTagName("SELECT");

            for (a = 0; a < svn.length; a++) {

                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}

function updateZoomedImg(src) {

    $('.main_vis img', this).attr('src', src);
}

function updateSrc(path_web,dest) {
    var tmp_string_large = document.getElementById('img_large').src;
    tmp_string = tmp_string_large.replace(/medium/,"large");

    document.getElementById('img_large').setAttribute('onclick','zoomImg(\''+tmp_string+'\',\''+path_web+'\')');
    document.getElementById('loupe').href = 'javascript:zoomImg(\''+tmp_string+'\',\''+path_web+'\');';
    $(".zoomLens").css({backgroundImage : 'url("'+tmp_string_large+'")' });

    if(dest=='vue') {

        var i = 0;
        var trouve = false;
        while (i<tab_js.length && !trouve) {

            trouve = (tab_js[i][0].substring(tab_js[i][0].lastIndexOf('/')+1)==tmp_string.substring(tmp_string.lastIndexOf('/')+1));
            i++;

        }

        cpt_img = (i-1);

    }
}

//Uilisée pour afficher les produits
function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop, fromPagination = false) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || parseFloat($("#nb_prod_default").val()),
        output = {};

    // Add misc values to output object
    output = triggerFilter(page, type, idObj, nb_product, 'filter_sticky', false);

    if (fromScrollTop || fromScrollBottom) {
        $('#initial_page').val(page + 1);
        seeAllBtnClick("remove");
    }

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }
    if (fromPagination) {
        output.page = $(".productBigPictures").length;
    }

    $('.wrap_flitre_pager .loader_scroll').addClass('loading');

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + 'ajax_reload_products.php',
        type: 'get',
        data: output,
        success: function (res) {
            var
                old_elm = document.getElementById('list_item'),
                content, itm_length;

            if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {

                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');
            content = res[0].trim();
            itm_length = parseFloat(res[1]);

            // This block of code updates the SEO-related link elements in the HTML document.
            let canonical = res[5];
            let prev = res[6];
            let next = res[7];

            $("link[rel^='canonical']").attr("href", path_root + canonical);
            $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);

            $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);
            $("link[rel^='alternate'][hreflang^='x-default']").attr("href", JSON.parse(res[8]).en);

            let hrefPrev = $("link[rel^='prev']");
            let hrefNext = $("link[rel^='next']");
            let commentPrev = $('#href_prev');
            let commentNext = $('#href_next');

            changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
            changeSeoElement(next, hrefNext, commentNext, 'next');

            if (content !== '') {

                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (fromScrollBottom || fromScrollTop) {
                    $(".autoLoad").fadeTo("fast", 0);
                } else {
                    old_elm.innerHTML = "";
                }

                document.getElementById('totalElems').value = itm_length;
                old_elm.insertAdjacentHTML('beforeend', content);

                $(".holder_submits button:first > span").html(Translator.translate('see_x_results', itm_length));
                if (itm_length > 1) {
                    $(".nb_products").html(itm_length + " " + Translator.translate('items'));
                } else {
                    $(".nb_products").html(itm_length + " " + Translator.translate('item'));
                }
                
                if (!fromPagination && $("body").hasClass("category")) {
                    $(".wrap_bg .push.item").remove();
                    $(".holder_topProduct .item").remove();
                    var newPush = $("#list_item #push_rayon_3");
                    $(".wrap_bg").append(newPush);
                    var newPushProduct = $("#list_item .wrapperProdVertical:first");
                    $(".holder_topProduct").append(newPushProduct);
                }

                if (window.lazyload !== undefined) {
                    lazyload.init();
                }
            }
        },
        complete: function () {
            window.setTimeout(function() {
                // force trigger on input hidden to fire the change() event
                $('#totalElems').trigger('change');
                $('#bottom_reached').val("0").trigger('change');
                $('#page').trigger('change');

                if (fromScrollTop || fromScrollBottom) {
                    seeAllBtnClick("add");
                }

                if (!fromScrollBottom && !fromScrollTop) {
                    setTimeout(function () {
                        $("html, body").animate({
                            scrollTop: 0
                        }, 200);
                    }, 250);
                    //reset initiialPage if from filter
                    $("#initial_page").trigger("change");
                }

                if (fromScrollTop || fromScrollBottom) {
                    $('#is_loading').val('0');
                }

                if (fromScrollBottom) {
                    $("#initial_page").val(page +1);
                }

                /**
                * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
                */
                var productLoaded = $(".productBigPictures").length;
                var total_element = document.getElementById('totalElems').value;
                var percentLoaded = (productLoaded * 100 / total_element);

                $('.nbProdDisplayed').html(Translator.translate('pagerTxt', productLoaded, total_element));

                $('.loadedGauge').html('<span style="width: ' + percentLoaded + '%;"></span>');

                if(productLoaded >= total_element) {
                    window.setTimeout(function() {
                        $('.wrap_flitre_pager .loader_scroll').removeClass('loading');
                    }, 2000);
                }

                if ($('#trigger_filtre').length) {
                    updateCheckedCount();
                }

            }, 1000);
        }
    });
}

function updateCheckedCount() {
    var checkedCount = $('#filter_sticky input:checked').length;
    var text_filters = Translator.translate('filtres');

    if (checkedCount > 0) {
        $('#trigger_filtre').html('<span>' + text_filters + ' (' + checkedCount + ')</span>');
    } else {
         $('#trigger_filtre').html('<span>' + text_filters + '</span>');
    }
}

$('body').on('change', '#filter_sticky input', function() {
    updateCheckedCount();
});

$('body').on('click touch', '#filter_sticky .reinitialiser .button', function() {
    updateCheckedCount();
});

var bloc_prod_xhr;

function paginate(page) {
    var form = document.getElementById('filter_sticky');

    // Check if the form element exists
    if (!form) {
        return;
    }
    
    var total_element = parseFloat(form.getAttribute('data-totalElems')),
        nb_prod = parseFloat(form.getAttribute('data-nb_prod')),
        nb_total_page = Math.ceil(total_element / nb_prod),
        pagination = $('.pager_wrapper'),
        nb_prod_default = parseFloat(form.getAttribute('data-nb_prod_default')),
        see_all = parseFloat(form.getAttribute('data-see_all'));

    page = page || 1;

    $.ajax({
        url: path_relative_root + 'ajax_reload_pagination.php',
        type: 'get',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page
        },
        success: function (res) {

            var productLoaded = $(".productBigPictures").length;
            var percentLoaded = (productLoaded * 100 / total_element);

            pagination.each(function () {
                if (nb_prod != 1000 && nb_prod < total_element) {
                    this.innerHTML = res + '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt', productLoaded, total_element) + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div>';
                } else if (total_element > nb_prod_default) {
                    this.innerHTML = '<div class="nbProdDisplayed">'+ Translator.translate('pagerTxt', productLoaded, total_element) + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div>';
                } else {
                    this.innerHTML = '';
                }
            });
        }
    });
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop, fromPagination) {

    var page_bloc;

    page = page || 1;
    page_bloc = parseInt(page - 1);

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    // Si on est sur la page de recherche
    if ($('#search_page').length > 0) {
        generateNewBlocSearch(page_bloc);
    } else {
        generateNewBlocProd(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop, fromPagination);
    }
}

function filterTrigger(event) {
    var filter_sticky = $('#filter_sticky');
    var trigger_filtre = $('#trigger_filtre');
    var shad_filter = $('#shad');
    var filter_trier_name = $('#filter_trier .filter_name');
    var top_rayon_wrapper = $('.top_rayon_wrapper');
    var product_price_order = $('#product_price_order');

    if ($('#filter_sticky').length) {
        if (filter_sticky.hasClass('actif')) {
            trigger_filtre.removeClass('actif');
            filter_sticky.removeClass('actif');
            shad_filter.removeClass('actif header_visible');
            top_rayon_wrapper.removeClass('filter_visible');
        } else {
            filter_sticky.addClass('actif');
            trigger_filtre.addClass('actif');
            shad_filter.addClass('actif header_visible');
            top_rayon_wrapper.addClass('filter_visible');
        }
    }

    product_price_order.removeClass('actif');
    filter_trier_name.removeClass('actif');

    $('#filter_sticky .selected_options').overlayScrollbars({
        overflowBehavior: {
            x: 'hidden',
        },
    });
}

function filterTrierPar() {
    var filter_sticky = $('#filter_sticky');
    var trigger_filtre = $('#trigger_filtre');
    var filter_trier_name = $('#filter_trier .filter_name');
    var shad_filter = $('#shad');
    var product_price_order = $('#product_price_order');
    var top_rayon_wrapper = $('.top_rayon_wrapper');

    if (product_price_order.hasClass('actif')) {
       product_price_order.removeClass('actif');
       filter_trier_name.removeClass('actif');
       shad_filter.removeClass('actif header_visible');
       top_rayon_wrapper.removeClass('filter_visible');

    } else {
        product_price_order.addClass('actif');
        filter_trier_name.addClass('actif');
        shad_filter.addClass('actif header_visible');
        top_rayon_wrapper.addClass('filter_visible');
    }

    filter_sticky.removeClass('actif');
    trigger_filtre.removeClass('actif');
}

function closeFiltre() {
    $('#filter_sticky, #trigger_filtre, #product_price_order, #filter_trier .filter_name').removeClass('actif');
    $('#shad').removeClass('actif header_visible');
    $('.top_rayon_wrapper').removeClass('filter_visible');
}

//Reset all fiters
function initAllFilters(fromSearch = false) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0);

    ctrl.forEach(function (elm) {
        if ((elm.type === 'checkbox' || elm.type === 'radio')) {
            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.click();

                // Remove is_checked classes
                if ($(elm).hasClass('is_checked')) {
                    $(elm).removeClass('is_checked');
                }
            }
        }
    });

    if (fromSearch === false) {
        generateNewBlocProd.call(form, 0);
    }

    $('a.tri_price.actif').removeClass('actif');
    $(".filtre_elem").each(function (index) {
        $(this).removeClass("is_checked");
    });

    $(".filters_dropdown_content input[type='checkbox']").each(function (index) {
        $(this).prop("checked", false);
    });
}

function closeAlerteStock(elem) {
    $('.bloc_add_alert_' + elem).hide();

    // Reset mail input
    $('.bloc_add_alert_' + elem + ' .alert_stock').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_form').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_confirmation').hide();
    $('#ligne_pointure_' + elem + ' input').prop('checked', false);
    $('#ligne_pointure_' + elem).show();
    $('#prod_info_' + elem + ' .rollover_left').show();
    $('#prod_info_' + elem + ' .productSizeFieldset').show();
}

/**
 * Function to manage the "Read more" button on the department page
 */
let scrollText = function () {
    let elementSeoText = $('.textSeo_bloc_description');
    if (elementSeoText.hasClass('see_more')) {
        elementSeoText.removeClass('see_more').addClass('see_less');
    } else {
        elementSeoText.removeClass('see_less').addClass('see_more');
    }
}
function showQteListe(elementID, idProduit, parentPath, suff) {
    var couleurs_id = "couleurProd";
    var tailles_id = "tailleProd";

    if (elementID != "liste_qte") {
        couleurs_id += "_" + idProduit;
        tailles_id += "_" + idProduit;
    }

    var listQte = $("#" + elementID);
    var alternative_listQte = $("#liste_qte_spe");

    if (listQte.length || alternative_listQte.length) {
        if (listQte.length) {
            listQte.html(
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' +
                parentPath +
                'img/sablier_2.gif" style="margin-bottom:-6px;margin-right:10px;" />Chargement...</td></tr></table>'
            );

            var couleurs = $("#" + couleurs_id).length ?
                $("#" + couleurs_id).val() :
                "none";
            var tailles = $("#" + tailles_id).length ?
                $("#" + tailles_id).val() :
                "none";
        } else {
            alternative_listQte.html(listQte.html());

            var couleurs = "none";
            var tailles = "none";
        }

        var response = ajax_file(
            parentPath +
            "ajax_liste_qte.php?idprod=" +
            idProduit +
            "&idcouleur=" +
            couleurs +
            "&idtaille=" +
            tailles +
            "&produit_lot_nb=" +
            $("#produit_lot_nb").val() +
            "&suff=" +
            (suff || "")
        );

        if (response) {
            if (listQte.length) {
                listQte.html(response);
            } else {
                alternative_listQte.html(response);
            }

            var select_stock = $("#qteProd");
            var regexp_epuise = /Stock /;
            var txt_stock = $("#qteProd>option:selected").text();

            if (
                select_stock.length > 0 &&
                txt_stock.match(regexp_epuise) != null
            ) {
                $("#add_alert").show();
                $("#add_basket").hide();
            } else {
                $("#add_alert").hide();
                $("#add_basket").show();
            }
        }
    }
}

function showQteListeGamme(elementID, idProduit, parentPath) {
    var listQte = document.getElementById(elementID);
    var alternative_listQte = document.getElementById(
        "liste_qte_spe_" + idProduit
    );

    if (listQte || alternative_listQte) {
        if (listQte) {
            if (document.getElementById("couleurProd_" + idProduit))
                var couleurs = document.getElementById(
                    "couleurProd_" + idProduit
                ).value;
            if (document.getElementById("tailleProd_" + idProduit))
                var tailles = document.getElementById("tailleProd_" + idProduit)
                    .value;
            listQte.innerHTML =
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
                parentPath +
                'img/sablier.gif" />Chargement...</td></tr></table>';
        } else {
            var couleurs = "none";
            var tailles = "none";
            alternative_listQte.innerHTML =
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
                parentPath +
                'img/sablier.gif" />Chargement...</td></tr></table>';
        }

        var string_params = "";
        if ($("#produit_lot_nb_" + idProduit).val() != undefined)
            string_params +=
            "&produit_lot_nb=" + $("#produit_lot_nb_" + idProduit).val();

        if ($("#qte_default_" + idProduit).val() != undefined)
            string_params +=
            "&qte_default=" + $("#qte_default_" + idProduit).val();

        var response = ajax_file(
            parentPath +
            "ajax_liste_qte_alerte.php?idprod=" +
            idProduit +
            "&idcouleur=" +
            couleurs +
            "&idtaille=" +
            tailles +
            string_params
        );

        if (response) {
            if (listQte) listQte.innerHTML = response;
            else alternative_listQte.innerHTML = response;
        }
    }
}

function showQteListeAssoc(elementID, idProduit, parentPath) {
    var listQte = document.getElementById(elementID);
    var alternative_listQte = document.getElementById(
        "liste_qte_spe_" + idProduit
    );
    listQte.innerHTML =
        '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
        parentPath +
        'img/sablier.gif" />Chargement...</td></tr></table>';
    var couleurs = document.getElementById("couleurProdAssoc_" + idProduit);
    var tailles = document.getElementById("tailleProdAssoc_" + idProduit);
    if (listQte || alternative_listQte) {
        if (listQte) {
            var couleurs = document.getElementById("couleurProd_" + idProduit)
                .value;
            var tailles = document.getElementById("tailleProd_" + idProduit)
                .value;
            listQte.innerHTML =
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
                parentPath +
                'img/sablier.gif" />Chargement...</td></tr></table>';
        } else {
            var couleurs = "none";
            var tailles = "none";
            alternative_listQte.innerHTML =
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
                parentPath +
                'img/sablier.gif" />Chargement...</td></tr></table>';
        }

        var response = ajax_file(
            parentPath +
            "ajax_liste_qte_alerte.php?idprod=" +
            idProduit +
            "&idcouleur=" +
            couleurs.value +
            "&idtaille=" +
            tailles.value
        );
        if (response) {
            // On ex�cute deux fois car replace ne remplace qu'une seule fois alors que nous avons deux occurences
            response = response.replace("qteProd_", "qteProdAssoc_");
            response = response.replace("qteProd_", "qteProdAssoc_");
            if (listQte) listQte.innerHTML = response;
            else alternative_listQte.innerHTML = response;
        }
    }
}

// Affiche la liste des auantites d'un produit (HTML Tag select)
function showQteListePanier(elementID, idProduit, i, paniervirtuelId) {
    var listQtePanier = document.getElementById(elementID);

    var couleurs = document.getElementById("couleurProd" + i);
    var tailles = document.getElementById("tailleProd" + i);

    var idCouleur = 0;
    var idTaille = 0;

    if (couleurs) idCouleur = couleurs.value;
    if (tailles) idTaille = tailles.value;

    if (listQtePanier) {
        var response = ajax_file(
            "ajax/ajax_liste_qte_panier.php?idprod=" +
            idProduit +
            "&idcouleur=" +
            idCouleur +
            "&idtaille=" +
            idTaille +
            "&i=" +
            i +
            "&panierId=" +
            paniervirtuelId
        );
        if (response) listQtePanier.innerHTML = response;
    }
}

function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        var expires = "; expires=" + date.toGMTString();
    } else {
        var expires = "";
    }

    document.cookie = name + "=" + value + expires + "; path=/";
}

function loadSelectedSize(cnt_sizes) {

    var sizes = document.getElementsByName('itm_size');

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_size_selected.php',
        success: function (response) {
            if (response && response.length > 0) {
                response = $.parseJSON(response);
                var selected_size = (response.selected_size);
                var current_size;
                var current_element;
                var sizeProd = document.getElementById("tailleProd");
                var html = '';
                
                for (var i = 0; i < sizes.length; i++) {
                    current_element = sizes.item(i).value;
                    if ((selected_size === current_element && current_element !== null) && (!sizes.item(i).classList.contains("disabled"))) {
                        sizes.item(i).checked = true;
                        sizeProd.value = current_element;
                        enableAddCart();
                    } else if (sizes.length === 1 && sizes[0].classList.contains("disabled")) {
                        $('#btn_add_alert').click();
                    }
                }
            }
        }
    });
}

$(window).on('pageshow', function() {
    if ($('body.product_page').length > 0) {
        fixProductSizeAutoComplete();
    }
});

function fixProductSizeAutoComplete() {
    var selectedSize = document.querySelector('.sizesList .size input[name="itm_size"]:checked');

    if (selectedSize) {
        $("#tailleProd").val($(selectedSize).val());        
        $(selectedSize).prop('checked', false);
        $(selectedSize).click();
    }
}

// Reload product from ref
function loadProduct(new_id, type, page, itm_rr_id) {

    var form = this;
    var old_id = this.getValue('produit_id');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var qte_itm = 1;

    if(this.elements.namedItem('qteProd')  ) {
         var qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var isCategSuper = this.getValue('isCategSuper') ? this.getValue('isCategSuper') : 0;

    // to avoid calling the view vertical_product.php in category_super/layouts when product's color is changed on search result page
    if ($('#search_page').length) {
       var isCategSuper = 0;
    }
    
    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    var data = {
        produit_id : old_id,
        produit_principal : old_id,
        couleurProd : col_id,
        tailleProd : sze_id,
        act : act,
        qteProd : qte_itm,
        hdn_product_id : old_id,
        hdn_is_model : is_model,
        produit_id_new : new_id,
        type : type,
        type_page : (page !== undefined && page !== '' ) ? page : 'product',
        is_achat_express :  0,
        is_achat_express_v2 : is_achat_express ? 1 : 0,
        cpt_prod : cpt_prod-1, 
        isCategSuper : isCategSuper
    };

    if (is_wishlist) {
         data.wishlist = true;
    }

    $.ajax({
         type : 'post',
         url : path_relative_root + 'ajax_get_product_regroup_ref.php',
         data : data,

        success : function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {

                 var datas = res.datas;

                 if (data.type_page == 'product') {

                     var $html_main = $(stripCombo(datas.html_main));
                     var main_wrapper = $('.product_main_wrapper', $html_main);
                     var zoom_wrapper = $('.zoom_main_wrapper', $html_main);
                     var feats_wrapper = $('.product_feats_wrapper', $html_main);
                     var assoc_products = $('.product_associations_wrapper', $html_main);

                     if (data.is_achat_express_v2) {
                         // Photo et information
                         var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');
                         $('#itm-' + itm_id).replaceWith($html_main);

                         var new_itm_id = new_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');
                         var margin_right = $('#itm-' + new_itm_id ).next().css('margin-right');

                         if ($(".homepage").length > 0 || $(".satellite_store_details").length > 0) {
                             save_description = $('#itm-' + new_itm_id + ' .wrapper_description').html();
                             $('#itm-' + new_itm_id + ' .wrapper_description').html('<div class="name">' + save_description + '</div>');
                             save_item_title = $('#itm-' + new_itm_id + ' .item_title').html();
                             $('#itm-' + new_itm_id + ' .item_title').html('<span>' + save_item_title + '</span>')
                             
                             if ($(".satellite_store_details").length>0) {
                                 $('#itm-' + new_itm_id ).addClass('swiper-slide');
                                 $('#itm-' + new_itm_id ).css('margin-right',margin_right);
                             }
                         } else if ($(".product_page").length > 0 || $(".rayon_lookbook").length > 0) {
                             if ($(".product_page").length > 0) {
                             } else if ($(".rayon_lookbook").length > 0) {
                                 $('#itm-' + new_itm_id + ' .imgWrapper').addClass('imgWrapperinlook');
                             }
                         }

                         $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                         $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                     } else if (is_wishlist) {
                         // Photo et information
                         $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                         // Description compo, livraison, guide tailles
                         $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());
                     } else {

                         var $html_middle = $(stripCombo(datas.html_middle));
                         var color = $("#ligne_couleur").html();
                         var taille = $("#ligne_pointure").html();
                         var contents = $('<div />').html($html_main);

                         // Photo et information
                         $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());
                         // Zoombox
                         $('.zoom_main_wrapper', '#site_global_wrap').html(zoom_wrapper.html());
                         // Description compo, livraison, guide tailles
                         $('.product_feats_wrapper', '#site_global_wrap').html(feats_wrapper.html());

                         $('.product_associations_wrapper').html(assoc_products.html());

                         var video = $('.wrapper_video', contents);

                         $("#ligne_couleur").html(color);
                         $('input.color').prop('checked', false);
                         $('input#color_' + data.couleurProd).prop('checked', true);

                         // On scroll en haut de la page dans le cas d'un regroupement
                         $('html, body').animate({
                             scrollTop: 0
                         }, 500);
                     }

                     if ($("#buyNowSwiper").length > 0) {
                         var buyNowSwiper = document.getElementById("buyNowSwiper");

                         if (buyNowSwiper.getElementsByClassName("swiper-slide").length) {

                             buyNowSwiper = new Swiper("#buyNowSwiper", {
                                 sliderPerView: 1,
                                 spaceBetween: 50,
                                 nextButton: '.swiper-button-next',
                                 prevButton: '.swiper-button-prev'
                             });
                         }
                     }
                 } else if (type_page == 'look') {

                     var $html_main = $(stripCombo(datas.html_main));
                     var $html_middle = $(stripCombo(datas.html_middle));
                     var color = $("#ligne_couleur").html();
                     var main_wrapper = $('.product_main_wrapper', $html_main);
                     var feats_wrapper = $('.product_feats_wrapper', $html_main);
                     var contents = $('<div />').html($html_main);

                     // Photo et information
                     $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                     // Description compo, livraison, guide tailles
                     $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());


                     $('#ref_look_' + produit_principal).html($('#ref_look_' + produit_principal, $html_main).html());
                     $('#look_add_lightbox_' + produit_principal).html($('#look_add_lightbox_' + produit_principal, $html_main).html());

                     calculTotal('slide_look_detail');
                     $('#itm_thumb_list').empty();
                     var thumbnails = loadPrevisu();
                     parseThumbList(thumbnails);

                     var $id_current = id_prod.substring(id_prod.lastIndexOf("_") + 1);
                     var i = array_look.indexOf(parseInt($id_current));

                     if (i != -1) {
                         array_look.splice(i, 1);
                     }

                     // Une fois les informations chargés on vas mettre à jour le select des quantités avec le nouveau produit choisie
                     showQteListeGamme('liste_qte_' + datas.product.id,  datas.product.id, path_relative_root);
                 }

                 json_colisages = res.stock;

                 // On definie les tailles qui ont encore du stock
                 if (!data.is_achat_express_v2) {
                     setAvailableSizes.call($('[name="itm_color"]:checked', $(form)));
                 }

                 if (wShop.$refs.wAlertestockForm) {
                     wShop.$refs.wAlertestockForm.reloadBloc += 1;
                 }

             } else {
                 $( '#btn_error' ).fadeIn();
             }
         },
         complete : function () {

             var heads      = $("[data-tab-head]");
             var containers = $("[data-tab-container]");

             if (window.YT_Player !== undefined) {

                 window.YT_Player.destroy();
                 window.YT_Player = undefined;
                 window.YT_vid_id = undefined;
             }

             var activateTab = function(event) {
                 event.preventDefault();

                 var element        = $(this);
                 var tab_number     = element.attr("data-tab");
                 var head_container = element.parent();
                 var container_name, container, scrollpane;

                 head_container.addClass("current").siblings().removeClass("current");
                 container_name = head_container.parent().attr("data-tab-head");
                 container      = containers.filter("[data-tab-container='" + container_name + "']");
                 scrollpane     = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                 if (scrollpane.length > 0) {
                     scrollpane.jScrollPane();
                 }
             };

             setTimeout(function () {
               function updateSrc(path_web, dest) {
                   var tmp_string_large = document.getElementById("img_large").src;
                   tmp_string = tmp_string_large.replace(/medium/, "large");

                   document
                       .getElementById("img_large")
                       .setAttribute(
                           "onclick",
                           "zoomImg('" + tmp_string + "','" + path_web + "')"
                       );
                   document.getElementById("loupe").href =
                       "javascript:zoomImg('" + tmp_string + "','" + path_web + "');";
                   $(".zoomLens").css({backgroundImage: 'url("' + tmp_string_large + '")'});

                   if (dest == "vue") {
                       var i = 0;
                       var trouve = false;
                       while (i < tab_js.length && !trouve) {
                           trouve =
                               tab_js[i][0].substring(tab_js[i][0].lastIndexOf("/") + 1) ==
                               tmp_string.substring(tmp_string.lastIndexOf("/") + 1);
                           i++;
                       }

                       cpt_img = i - 1;
                   }
               }

                function updateZoomedImg(src) {
                   $(".main_vis img", this).attr("src", src);
                }

                /* Fin reinitialisation sliders visus */

                var more_vis = $('.more_vis');
                var grabber;

                more_vis.each(function () {

                   var jqzoom = $(this).next('.product_page .main_vis_fp_swiper');

                   if (jqzoom.length > 0) {

                       $(this).height(jqzoom.height());

                       if (document.getElementById('visual_scroller')) {

                           setTimeout(function () {

                               grabber = new Grabber(document.getElementById('visual_scroller'), true, false);
                           }, 250);
                       }
                   }

                   var zoom_box_wrapper = $(this).next('#zoom_box_wrapper');

                   if (zoom_box_wrapper.length > 0) {

                       $(this).height(zoom_box_wrapper.height());

                       if (document.getElementById('thumbs_scroller')) {

                           setTimeout(function () {

                               grabber = new Grabber(document.getElementById('thumbs_scroller'), true, false);
                           }, 250);
                       }
                   }
                });

               // Resetting alert stock field
               $('.bloc_add_alert_form').hide();

               var productImgsWrapper = $(".productImgsWrapper");
               var productDescrWrapper = $(".productDescrWrapper");

               $(document).on("scroll", function () {

                   var scrollTop = $(document).scrollTop();
                   var newTop = (scrollTop - (productImgsWrapper.innerHeight() - productDescrWrapper.innerHeight()));

                   if(scrollTop >= productImgsWrapper.innerHeight() - productDescrWrapper.innerHeight()) {

                       productDescrWrapper.css("top", "-" + newTop + "px");
                   } else {

                       productDescrWrapper.css("top", "auto");
                   }
               });

                $(window).on('resize', function () {

                   more_vis.each(function () {

                       var jqzoom = $(this).next('.product_page .main_vis_fp_swiper');

                       if (jqzoom.length > 0) {

                           $(this).height(jqzoom.height());
                       }

                       var jqzoom = $(this).next('#zoom_box_wrapper');

                       if (jqzoom.length > 0) {

                           $(this).height(zoom_box_wrapper.height());
                       }
                   });
                });

               /***********
               FP Color
               ***********/
               $(document).ready(function() {
                   if ($('body.category #site_global_wrap .rayon_lookbook').length) {
                       new SimpleBar($('.category .choices_list.choice_color')[0]);
                   }

                   $('#ligne_couleur').mouseover(function(e) {
                   e.preventDefault();
                       $('#ligne_couleur .js-dropp-action').addClass('drop_button');
                       $('.choices_list.choice_color').addClass('js-visible');
                       $('#ligne_couleur .drop_btn_big:not(".no_dropdown")').addClass('opened');
                       $('.choices_list.size').removeClass('js-visible');
                       $('#ligne_pointure .js-dropp-action').removeClass('drop_button');
                       $('#ligne_couleur').removeClass('closed');
                   });

                   $('#ligne_couleur').mouseout(function(){
                       $('#ligne_couleur .js-dropp-action').removeClass('drop_button');
                       $('.choices_list.choice_color').removeClass('js-visible');
                       $('#ligne_couleur').addClass('closed');

                       window.setTimeout(function() {
                           $('#ligne_couleur.closed .simplebar-content-wrapper').animate({ scrollTop: 0 }, "fast");
                       }, 1500);
                   });

                   // Using as fake input select dropdown
                   $('#ligne_couleur input').click(function() {
                       $(this).addClass('js-visible').siblings().removeClass('js-visible');
                       $('.choices_list.color,#ligne_couleur .js-dropp-action').removeClass('js-visible');
                       $('#ligne_couleur .drop_btn_big').removeClass('opened');
                       $(this).parent().addClass('active').siblings().removeClass('active');
                   });

                   $('.choices_list.choice_color .input_check').click(function() {
                       $('#ligne_couleur .dropp-header__btn').removeClass('drop_button');
                       $('.choices_list.choice_color.js-visible').removeClass('js-visible');
                       $('#ligne_couleur .drop_btn_big').removeClass('opened');
                       $('.choices_list.choice_color').addClass('color_selected');
                   });

                   $('#ligne_couleur .dropp-header__btn').click(function() {
                       $('.choices_list.choice_color.color_selected').removeClass('color_selected');
                   });

                   $('#ligne_couleur .prod_listes .choices_list .form_itm.color input:checked').parent('.form_itm').addClass('active');

                   window.setTimeout(function() {
                       $('#ligne_couleur .dropp .choices_list .form_itm.color').css('opacity','1');
                   }, 500);

               });


               setTimeout(function () {
                       checkSubmitState();
                       eventButton();
                   }, 500);
               }, 250);

             if ($(".productSwiper.swiper-container").length && $('body').hasClass('product_page')) {
               var productSwiper_fp_hp = new Swiper(".productSwiper.swiper-container", {
                   slidesPerView: 1,
                   observer: true,
                   observeParents: true,
                   navigation: {
                     nextEl: '.pas-button-next',
                     prevEl: '.pas-button-prev',
                   },
                   preloadImages: false,
                   lazy: true,
                   loadPrevNext: true,
                   loadOnTransitionStart: true
               });
             }

             setTimeout(function () {
               $(".product_page .productSwiper .swiper-slide-off").css('opacity','1');
             }, 1500);

           window.setTimeout(function() {
               var w = $(window).width();
               if(w < 1300) {
                    $('#tete_gondole').addClass('small_swiper');
                    
                   if ($("#productAssociationSwiper .swiper-slide.item").length > 2) {
                       var productAssociationSwiper = new Swiper('#productAssociationSwiper', {
                            slidesPerView: 2,
                            spaceBetween: 40,
                            loop: false,
                            navigation: {
                               nextEl: '.btn_nxt',
                               prevEl: '.btn_prv',
                           },
                       });
                      
                   } else {
                       $('#productAssociationSwiper').addClass('no_swiper');
                   }
               }
               else {
                   if ($("#productAssociationSwiper .swiper-slide.item").length >= 4) {
                       var productAssociationSwiper = new Swiper('#productAssociationSwiper', {
                            slidesPerView: 4,
                            spaceBetween: 55,
                            allowTouchMove: false,
                            loop: false,
                            navigation: {
                               nextEl: '.btn_nxt',
                               prevEl: '.btn_prv',
                           },
                           breakpoints: {
                               1360: {
                                   slidesPerView: 3,
                                   spaceBetween: 30,
                               },
                               1160: {
                                   slidesPerView: 2,
                                   spaceBetween: 30,
                               },
                           },
                       });
                       
                   } else if ($("#productAssociationSwiper .swiper-slide.item").length == 3) {

                       $('#productAssociationSwiper').css('max-width', '1260px');

                       var productAssociationSwiper2 = new Swiper('#productAssociationSwiper', {
                            slidesPerView: 3,
                            spaceBetween: 55,
                            allowTouchMove: false,
                            loop: false,
                            navigation: {
                               nextEl: '.btn_nxt',
                               prevEl: '.btn_prv',
                           },
                           breakpoints: {
                               1360: {
                                   slidesPerView: 3,
                                   spaceBetween: 30,
                               },
                               1160: {
                                   slidesPerView: 2,
                                   spaceBetween: 30,
                               },
                           },
                       });


                       
                   } else {
                       $('#productAssociationSwiper').addClass('no_swiper');
                   }

                   $('#tete_gondole').removeClass('small_swiper');
               }

               if ($(".productSwiper.swiper-container").length) {
                 var productSwiper = new Swiper(".productSwiper.swiper-container", {
                     slidesPerView: 1,
                     observer: true,
                     observeParents: true,
                     navigation: {
                         nextEl: '.rollover_rayonsNext',
                         prevEl: '.rollover_rayonsPrev',
                     },
                     preloadImages: false,
                     lazy: true,
                     loadPrevNext: true,
                     loadOnTransitionStart: true
                 }); 
             }
           
           }, 500);

           $('#bloc_add_alert #btn_add_alert').click(function(){
             $(this).hide();
           });

           $('.bloc_add_alert_confirmation button').click(function(){
             $('#bloc_add_alert #btn_add_alert').show();
           });

           window.setTimeout(function() {
                 var swiper_home_main_push = $("#productAssociationSwiper .productBigPictures .wrap_video").width();
                 $("#productAssociationSwiper .productBigPictures .wrap_video iframe").height(swiper_home_main_push * '1.285');
            }, 1500);


           window.setTimeout(function() {
             var swiper_width_bug = $('#productAssociationSwiper .content-wrapper .item.swiper-slide[data-swiper-slide-index]').width();
             $('#productAssociationSwiper .content-wrapper .item.swiper-slide[data-pi]').css('max-width', swiper_width_bug);
           }, 5000);

             heads.each(function(){
                 var head = $(this);
                 var children = head.children();
                 children.removeClass("current").filter(":first").addClass("current");
                 children.find('[data-tab]').click(activateTab);
             });

             $("[data-tab-container]").children('div').eq(Math.abs(1 - $('.current', "[data-tab-head]").index())).css('display', 'none');

             var assocs_elm = $('#assocs_slider', '#site_global_wrap').get(0);
             var ranges_elm = $('#ranges_slider', '#site_global_wrap').get(0);

             getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd,'', data.couleurProd, itm_rr_id, is_wishlist);

             $('#addToWishlistButton', '#site_global_wrap').on('click', addToWishlist);

             if (!data.is_achat_express_v2) {
                 checkProductInWishlist(data.produit_id_new);
             }

             if (is_wishlist) {
                 // event sur le bouton d'ajout à la wishlist
                 $("#addToWishlistButton").on("click", addToWishlist);
             }

             $(document).ready(function video_responsive_r() {
                 window.setTimeout(function() {
                     $(window).on('load resize', function () {

                         var video_max_height = $('.home-module.slider-module #productAssociationSwiper .productSwiper').height();
                         $('.home-module.slider-module #productAssociationSwiper .wrap_video iframe').css('height', video_max_height);
                     
                     }).trigger('load resize');
                 }, 2000);
             });

             //Simulates scroll (2px) to load images
             window.scrollTo(0, $(window).scrollTop()+2);
         }
    });
}

function deleteFlexAlias(evt) {
    var alias = this.form;
    var alias_id = alias.alias_id.value.trim();
    var parent = $(alias.parentElement);

    evt.preventDefault();

    $.ajax({
        url: path_relative_root + "ajax_delete_alias.php",
        method: "GET",
        data: {id: alias_id},
        success: function(res) {
            if (res == 1) {
                parent.animate({height: 0, opacity: 0}, 350, function() {
                    parent.remove();
                });
            }
        },
    });

    return false;
}

function fadePanier(id, nbelem) {
    for (var i = 1; i <= nbelem; i++) {
        if (id != i && $("#texte_question_" + i).css('display') == 'block') {
            $("#texte_question_" + i).slideUp(400);
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {
        $("#texte_question_" + id).slideDown(400);
    } else {
        $("#texte_question_" + id).slideUp(400);
    }
}

//Toggle product details in cart right column
$(".total_produit").on("click", function () {
    $(this).toggleClass("open");
    $(".total_produit_dropdown").slideToggle();
});

function checkProductInWishlist() {
    var prod_id = $("#produit_id").val();
    var couleurProd = $("#couleurProd");
    var tailleProd = $("#tailleProd");

    if (couleurProd.length) {
        couleurProd = $("#couleurProd").val();
    } else {
        couleurProd = 0;
    }
    
    if (tailleProd.length) {
        tailleProd = $("#tailleProd").val();
    } else {
        tailleProd = 0;
    }

    if (typeof prod_id != "undefined" && typeof couleurProd != "undefined" && typeof tailleProd != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_wishlist.php',
            data: {'product_id': prod_id, 'size_id': tailleProd, 'color_id': couleurProd},
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                    return true;
                } else {
                    changeBtnAddWishlist(false);
                    return false;
                }
            }
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

// Show lost password form
var afficheLostPassword = function (evt) {
    var signIn = $("#auth");
    var passWd = $("#resetPass");
    var subLogin = $("#subtitleLogin");
    var subPassword = $("#subtitlePassword");

    evt.preventDefault();

    signIn.animate(
        {
            opacity: 0,
        },
        600,
        function () {
            signIn.css("display", "none");

            passWd.css("display", "").animate(
                {
                    opacity: 1,
                },
                600
            );
        }
    );

    subLogin.animate(
        {
            opacity: 0,
        },
        600,
        function () {
            subLogin.css("display", "none");
            subPassword.css("display", "block").animate(
                {
                    opacity: 1,
                },
                600
            );
        }
    );
};


function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
}

function triggerFunctionOnSearchEnd () {
    // Init categ swiper
    if ($('#slideCateg .swiper-slide').length) {
        var slideCateg = new Swiper('#slideCateg', {
            slidesPerView: 'auto',
            navigation: {
                nextEl: ".sub_categ_wrapper .slide_next",
                prevEl: ".sub_categ_wrapper .slide_prev",
            },
        });
    }
}

function openAlerteStock(id) {
    $('.bloc_add_alert_' + id).show();
    $('form a#warn_available_' + id).addClass('none-important');
}

function closeAlerteStockWishlist(elem) {
$('.bloc_add_alert_' + elem).hide();
$('form a#warn_available_' + elem).removeClass('none-important');
}

function updateCartUI() {
    if ($('body').hasClass('cart')) {

        $('.cart_main_table.wishlist .cart_product_line .cart_product_desc .cart_product_modify .tunnel_wish_nostock').each(function () {
            $(this).closest('.cart_product_line').find('.cart_product_pic').append($(this));
        });
    }
}